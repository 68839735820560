/* -------------------------------------------------------------------------- *\
    TYPE STYLES
\* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- *\
  RESETS
\* -------------------------------------------------------------------------- */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
a {
  color: var(--text-color);
  font-family: var(--regular-font);
  font-size: 100%;
  font-style: normal;
  font-weight: var(--regular-font-weight);
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

a {
  color: var(--color-primary);
  cursor: pointer;
  font-size: inherit;
  font-weight: inherit;
  text-decoration: none;
  transition: color 0.6s;

  &:visited {
    color: var(--color-primary);
  }

  &:hover {
    color: var(--color-primary--darker);
  }
}

p {
  margin-bottom: var(--unit);
}

b,
strong,
.kirk,
.is-bold,
.is-strong {
  font-weight: var(--bold-font-weight) !important;
}

i,
em {
  font-style: italic;
}

.type--monospace {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: var(--regular-font-weight);
}

.minion {
  @include minion();

  &--bold {
    font-weight: var(--bold-font-weight);
  }

  &--caps {
    @include minion(normal, 0);

    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

.brevier {
  @include brevier();

  &--bold {
    font-weight: var(--bold-font-weight);
  }

  &--caps {
    @include brevier(normal, 0);

    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

.primer {
  @include primer();

  line-height: 1.5;

  &.kirk,
  &.is-bold,
  &--bold {
    font-weight: var(--bold-font-weight);
  }
}

.long-primer {
  @include long-primer();

  &.kirk,
  &.is-bold,
  &--bold {
    font-weight: var(--bold-font-weight);
  }
}

.pica {
  @include pica();

  &--bold {
    @include pica(bold, 0);
  }
}

.paragon {
  @include paragon(bold, 0);
}

.minion,
.brevier,
.primer,
.long-primer,
.pica,
.pica-bold,
.paragon {
  &.is-secondary {
    color: var(--color-grey--dark);
  }
}

.type--secondary {
  color: var(--color-grey--dark);
}

.type--ghost {
  color: var(--color--white);
}

.type--capitalize {
  text-transform: capitalize;
}
