/*  Use to hide an icon link until a label is hovered over
 *  Primarily used to show a copy to clipboard link for urls
 *  when hovering over a camera or monitor name
 */

/* ------------------------------------------------------------------------------- *\
    <h2 class="o-icon-reveal">
        <span class="o-icon-reveal__label">{{ camera.name }}</span>
        <span class="o-icon-reveal__icon">
            <app-copy-link [fullRoute]="'/cameras?cameraId=' + camera.id" [name]="'camera'"></app-copy-link>
        </span>
    </h2>
/* ------------------------------------------------------------------------------- */

.o-icon-reveal {
  display: flex;
  font-size: inherit;
  font-weight: inherit;
  border-radius: 50px;

  &:hover {
    background-color: var(--color-grey--ultralight);
    color: var(--color-grey--dark);

    > .o-icon-reveal__icon {
      opacity: 1;
    }
  }

  &.disabled:hover {
    background-color: unset;
    color: unset;

    > .o-icon-reveal__icon {
      opacity: 0;
    }
  }

  &__label {
    padding: calc(var(--unit) / 4) calc(var(--unit) / 2);
  }

  &__icon {
    align-items: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    opacity: 0;
    padding: calc(var(--unit) / 4) calc(var(--unit) / 2);
    transition: opacity var(--transition-time);

    .is-icon {
      @include long-primer(normal, 0);
    }
  }
}

.is-compact {
  .o-icon-reveal {
    margin-left: calc(-0.5 * var(--unit));
    position: relative;

    &__icon {
      position: absolute;
      right: 0;
    }

    &__label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      .o-icon-reveal__icon {
        background-color: var(--color-primary);
        color: var(--color-white);
        height: 100%;
      }
    }
  }
}
