.o-feature-panel {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  overflow: hidden;

  &.is-muted {
    border: 1px solid var(--border-color);
  }

  &.is-contained {
    background-color: var(--color-grey--ultralight);
  }

  &__head {
    align-items: center;
    background-color: var(--color-primary);
    color: var(--color-white);
    display: grid;
    gap: var(--unit);
    grid-template-columns: min-content auto min-content;
    height: 48px;
    overflow: hidden;
    padding-inline: var(--unit);
    text-overflow: ellipsis;

    .is-muted & {
      background-color: var(--color-grey--light);
      color: var(--color-grey--dark);
    }

    > .is-icon {
      width: min-content;
    }

    > .is-label {
      @include brevier(normal, 0);

      color: var(--color-white);
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: uppercase;
      white-space: nowrap;

      .is-muted & {
        color: var(--color-grey--dark);
      }
    }

    > .is-icon-secondary {
      margin-left: auto;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--unit);
    padding: var(--unit);

    .has-border &,
    .is-contained & {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
      border-bottom: 1px solid var(--border-color);
      border-left: 1px solid var(--border-color);
      border-right: 1px solid var(--border-color);
    }

    .is-muted & {
      border: none;
    }

    .is-stacked & {
      @media screen and (min-width: 768px) {
        align-items: initial;
        flex-direction: column;
        justify-content: initial;
      }
    }

    @media screen and (min-width: 768px) {
      align-items: center;
      flex-direction: row;
      gap: calc(2 * var(--unit));
      justify-content: space-between;
      padding: calc(2 * var(--unit));
    }
  }

  &__message {
    order: 2;
    @media screen and (min-width: 768px) {
      order: initial;
      width: 66%;
    }

    > p {
      @include brevier();
    }

    > .is-primary-action {
      margin-left: auto;

      @media screen and (min-width: 768px) {
        margin-left: initial;
      }
    }
  }

  &__media {
    min-width: 200px;
    order: 1;

    @media screen and (min-width: 768px) {
      order: initial;
      width: 33%;
    }

    > .is-media {
      display: block;
      margin-inline: auto;
    }

    > .is-greyscale {
      filter: grayscale(1);
    }
  }
}
