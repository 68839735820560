:root {
  .o-expansion-wrap {
    /*  Above selectors for specificity to override default 
     *  mat-expansion styles
     */

    overflow: auto;
    .o-expansion {
      &__head {
        border-top-style: solid;
        border-top-width: 2px;
        padding-bottom: var(--unit);
        padding-top: var(--unit);

        &.is-online {
          border-top-color: var(--online-color);
        }
      }

      &__item {
        border-radius: var(--border-radius);
        margin-bottom: calc(0.5 * var(--unit));

        @media screen and (min-width: 768px) {
          margin-bottom: var(--unit);
        }
      }

      &__title {
        align-items: center;
        column-gap: var(--unit);
        display: grid;
        grid-template-areas:
          'icon title'
          'icon info';
        grid-template-columns: 24px auto;
        overflow: hidden;

        @media screen and (min-width: 1024px) {
          column-gap: calc(2 * var(--unit));
        }

        > .is-icon {
          grid-area: icon;

          &.is-online {
            color: var(--online-color);
          }

          &.is-offline {
            color: var(--offline-color);
          }
        }

        > .is-title-text {
          grid-area: title;
          overflow: hidden;
        }

        > .is-info-bar {
          grid-area: info;
        }
      }

      &__body {
        margin-block: var(--unit);
      }
    }
  }
}
