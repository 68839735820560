//*** SCREEN SIZES ***//

// 0 - 768 px
$screen-sm-max: 768px;

// 768 - 1356 px
$screen-md-min: 768px;
$screen-md-max: 1356px;

// 1024 - 1356 px
$screen-lg-min: 1024px;
$screen-lg-max: 1356px;

// 1356 - inf px
$screen-xl-min: 1356px;

$screen-sm: 'screen and (max-width: #{$screen-sm-max})';
$screen-md: 'screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max})';
$screen-lg: 'screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max})';
$screen-lg: 'screen and (min-width: #{$screen-xl-min})';

//*** How to use? Like this: ***//
// @include smMin{
//   p{
//     color: red;
//   }
// }
//
// .class{
//   text-align: left;
//   @include mdMin{
//     text-align: right;
//   }
// }

//*** Mixins ***//

@mixin respondFromTo($size_from, $size_to) {
  @media screen and (min-width: $size_from) and (max-width: $size_to) {
    @content;
  }
}

@mixin smMax {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin mdMin {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin mdMax {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin lgMin {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin lgMax {
  @media screen and (max-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xlMin {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin smOnly {
  @media #{ $screen-sm } {
    @content;
  }
}

@mixin mdOnly {
  @include respondFromTo($screen-md-min, $screen-md-max) {
    @content;
  }
}

@mixin lgOnly {
  @include respondFromTo($screen-lg-min, $screen-lg-max) {
    @content;
  }
}

@mixin xlOnly {
  @media #{ $screen-xl } {
    @content;
  }
}
