html,
body {
  margin: 0;
  font-family: 'IBM Plex Sans', Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

/* -------------------------------------------------------------------------- *\
    IMAGES
\* -------------------------------------------------------------------------- */
img,
picture {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

form {
  width: 100%;
}
