/*  ------------------------------------------------------------------------  *\
        distinct list item stying with background color and rounded corners
        see: https://docs.hoxton.ai/mason/lists-rounded
\*  ----------------------------------------------------------------------    */

.o-list__item--rounded {
  @include primer(normal, 0);

  align-items: center;
  background-color: var(--color-grey--ultralight);
  border-radius: 50px;
  cursor: initial;
  display: flex;
  margin: calc(var(--unit) / 2) var(--unit);
  padding-block: calc(0.5 * var(--unit));
  padding-inline: var(--unit);
  transition: background-color var(--transition-time);

  /*  success colour background
       */
  &.has-success {
    background-color: var(--success-color--light);

    &.is-link:hover {
      background-color: transparent;
      color: var(--color-primary);
    }
  }

  /*  warning color background
       */
  &.has-warning {
    background-color: var(--warning-color--light);

    &.is-link:hover {
      background-color: transparent;
      color: var(--color-primary);
    }
  }

  /*  error color background
       */
  &.has-error {
    background-color: var(--error-color--light);

    &.is-link:hover {
      background-color: transparent;
      color: var(--color-primary);
    }
  }

  &.is-link {
    cursor: pointer;

    &:hover {
      background-color: var(--color-grey--light);
    }
  }
}
