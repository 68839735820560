/* ------------------------------------------------------------------------ *\
    All headings have two lines. Heading and subhead.
    If you want a heading with only one line, use a regular text style.
    Style name is based on the largest font size. eg:
    .o-heading--primer, will use primer font size for the main heading
    and the subhead font will be automatically set.
\* ----------------------------------------------------------------------- */

.o-title--pica {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--unit);

  > .left-side-icon {
    color: var(--color-grey--dark);
    cursor: pointer;
    padding-right: var(--unit);
  }

  &__text {
    color: var(--text-color);
    margin-right: auto;

    > .is-primary-text {
      @include pica(bold, 0);
    }

    > .is-secondary-text {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
    }
  }

  > .right-side-icon {
    background-color: transparent;
    border-radius: 50%;
    box-sizing: content-box;
    color: var(--color-grey--dark);
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: calc(0.5 * var(--unit));
    transition: all var(--transition-time);

    &:hover {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }
}

.o-title--primer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--unit);

  > .left-side-icon {
    color: var(--color-grey--dark);
    cursor: pointer;
    padding-right: var(--unit);
  }

  &__text {
    color: var(--text-color);
    margin-right: auto;

    > .is-primary-text {
      @include primer(bold, 0);
    }

    > .is-secondary-text {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
    }
  }

  > .right-side-icon {
    background-color: transparent;
    border-radius: 50%;
    box-sizing: content-box;
    color: var(--color-grey--dark);
    cursor: pointer;
    display: flex;
    overflow: hidden;
    padding: calc(0.5 * var(--unit));
    transition: all var(--transition-time);

    &:hover {
      background-color: var(--color-white);
      color: var(--color-black);
    }
  }
}
