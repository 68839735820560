/*  ---------------------------------------- *\
    See: https://docs.hoxton.ai/mason/lists

\*  ---------------------------------------  */

.o-list {
  list-style: none;
  margin: 0;
  padding: 0;

  &.has-border {
    border: 1px solid var(--border-color);
  }

  /*  individual list item
   */
  &__item {
    @include primer();

    align-items: center;
    display: flex;
    margin: 0;
    padding: var(--unit);

    /*  extend .has-small-text to parent .o-list
     */
    .has-small-text &,
    .is-compact & {
      @include brevier(normal, 0);
    }

    /*  extend .is-light to parent .o-list
     */
    .is-light & {
      color: var(--color-white);
    }

    /*  extend .is-compressed to parent .o-list
        will reduce vertical/block padding between list items
     */
    .is-compressed &,
    .is-compact & {
      padding-bottom: calc(0.5 * var(--unit));
      padding-top: calc(0.5 * var(--unit));

      &:first-of-type {
        padding-top: var(--unit);
      }

      &:last-of-type {
        padding-bottom: var(--unit);
      }
    }

    /*  extend .has-divider to parent .o-list
        add a horizontal divider between list items
     */
    .has-divider & {
      border-bottom: 1px solid var(--border-color);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &.has-error {
      background-color: var(--color-red--light);
      margin-bottom: 2px;
    }

    &.is-link {
      cursor: pointer;

      &:hover {
        background-color: var(--color-grey--ultralight);
      }

      &.has-error {
        transition: background-color var(--transition-time);

        &:hover {
          background-color: var(--error-color--light-hover);
        }
      }
    }
  }

  /*  use as element inside .o-list__item to give link styling
      necessary when element isn't an <a> - which is the case in
      some angular components
   */
  @at-root {
    :root {
      .o-list--is-link {
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        color: inherit;
        display: flex;
        flex: 1;
        font-size: initial;
        margin: calc(-1 * var(--unit));
        max-width: initial;
        overflow: hidden;
        padding: var(--unit);
        transition: background-color var(--transition-time);

        &:hover {
          background-color: var(--color-grey--ultralight);
        }

        &.is-disabled {
          color: var(--disabled-color);
          cursor: initial;

          &:hover {
            background-color: transparent;
          }
        }

        &.has-no-padding {
          padding: 0;
        }
      }
    }
  }

  /*  contain the main list text
      necessary for layout when list item also contains icons or secondary text
   */
  &__item-text {
    color: var(--text-color);
    flex: 1;
    margin-right: var(--unit);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    /*  only needed if used alongside .is-secondary-text,
     *  or .is-overline-text.
     */
    > .is-primary-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @at-root {
        .o-list--is-link.is-disabled .is-primary-text {
          color: var(--disabled-color);
        }
      }
      .info-text {
        @include brevier();

        color: var(--color-grey--dark);
      }
    }

    /*  Subhead or overline text style in list item text elements
     */
    > .is-secondary-text,
    > .is-overline-text {
      @include brevier();

      color: var(--color-grey--dark);
      display: block;
      line-height: 1;
      margin-bottom: 0;

      /*  forcing disabled styles
        */
      @at-root {
        .o-list--is-link.is-disabled .is-secondary-text,
        .o-list--is-link.is-disabled .is-overline-text {
          color: var(--disabled-color);
        }
      }
    }

    /*  fix to get correct text overflow styles within a flex element.
        wrap content within a <div> inside the .o-list__item-text element
     */
    > * {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    /*  deprecated
     *  extend .is-light onto parent .o-list
     */
    .is-light & {
      color: var(--color-white);
    }

    /*  deprecated
        extend .is-small onto parent .o-list
     */
    &.is-brevier {
      @include brevier(normal, 0);
    }

    &.is-primer-bold {
      @include primer(bold, 0);
    }

    /*  deprecated
        extend .is-small onto parent .o-list
     */
    .has-small-text & {
      @include brevier(normal, 0);
    }

    /*  force disabled link styles
     */
    @at-root {
      .o-list--is-link.is-disabled .o-list__item-text {
        color: var(--disabled-color);
      }
    }
  }

  /*  List item icon
   */
  &__item-icon {
    color: var(--icon-color);
    fill: var(--icon-color);
    line-height: 1;
    margin-right: var(--unit);

    .is-light & {
      color: var(--color-white);
    }

    /*  forcing disabled styles
     */
    @at-root {
      .o-list--is-link.is-disabled .o-list__item-icon {
        color: var(--disabled-color);
      }
    }

    /*  icon color can be modified based on state/context
     */
    > .is-icon,
    > mat-icon {
      &.is-offline {
        color: var(--offline-color);
      }

      &.is-alert,
      &.is-online {
        color: var(--online-color);
      }

      &.has-bad-connection {
        color: var(--warning-color);
      }

      &.is-disabled {
        color: var(--disabled-color);
      }

      &.is-selected {
        color: var(--color-primary);

        /*  force disabled styles
         */
        @at-root {
          .o-list--is-link.is-disabled .is-selected {
            color: var(--disabled-color);
          }
        }
      }

      &.is-dark-grey {
        color: var(--color-grey--dark);
      }
    }
  }

  /*  secondary text on list items
   *  distinct from secondary text INSIDE .o-list__item-text
   *  this element aligns to far right of the list item
   */
  &__item-secondary-text {
    align-items: center;
    color: var(--icon-color);
    display: flex;
    line-height: 1;
    margin-left: auto;
  }

  /*  same position as .o-list__item-secondary-text
   *  but holds an icon rather than text
   */
  &__item-secondary-icon {
    align-items: center;
    color: var(--icon-color);
    display: flex;
    fill: var(--icon-color);
    line-height: 1;
    margin-left: auto;
    margin-right: 0;

    $icon-size: 40px;

    /*  contained/background colour styles for secondary icons
     */
    &.is-contained {
      background-color: var(--color-grey--ultralight);
      border-radius: 50%;

      .has-success & {
        background-color: var(--success-color);
        color: var(--color-white);
      }

      .has-warning & {
        background-color: var(--warning-color);
        color: var(--color-white);
      }

      .has-error & {
        background-color: var(--error-color);
        color: var(--color-white);
      }
    }

    @at-root {
      a.o-list__item-secondary-icon {
        align-items: center;
        background-color: transparent;
        border-radius: 50%;
        color: var(--icon-color);
        cursor: pointer;
        display: flex;
        height: var(--icon-size);
        justify-content: center;
        overflow: hidden;
        transition: background-color var(--transition-time);
        width: var(--icon-size);

        &:hover {
          background-color: var(--color-grey--ultralight);
        }

        > * {
          color: inherit;
        }
      }
    }

    /*  this icon is often highlighted to indicate it
        is the selected/active item in a menu
     */
    .is-selected {
      color: var(--color-primary);
    }

    /*  we have some instances where a count sits alongside the icon
        this styles that,
     */
    &-text {
      margin-inline-start: calc(0.5 * var(--unit));
    }
  }
}
