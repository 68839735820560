.o-user-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  border-color: var(--border-color);
  border-top-style: solid;
  border-top-width: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &.is-active {
    border-top-color: var(--online-color);
  }

  &.is-disabled {
    border-top-color: var(--disabled-color);
  }

  &__title {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: grid;
    gap: var(--unit);
    grid-template-columns: min-content auto min-content;
    padding: var(--unit);

    $avatar-size: 40px;

    > .is-avatar {
      align-items: center;
      background-color: var(--icon-color);
      border-radius: 50%;
      color: var(--color-white);
      display: flex;
      flex-shrink: 0;
      height: $avatar-size;
      justify-content: center;
      width: $avatar-size;
    }

    > .is-title-role {
      overflow: hidden;

      > :nth-child(1) {
        @include primer(bold, 0);
      }

      > :nth-child(2) {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
        text-transform: uppercase;
      }

      > :nth-child(1),
      > :nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__actions {
    box-sizing: content-box;
    display: flex;
    flex-shrink: 0;
    gap: calc(0.5 * var(--unit));

    > .is-action {
      background-color: transparent;
      border-radius: 50%;
      box-sizing: content-box;
      color: var(--color-grey--dark);
      cursor: pointer;
      flex-shrink: 0;
      padding: calc(0.5 * var(--unit));
      transition: all var(--transition-time);

      &:hover {
        background-color: var(--color-grey--ultralight);
        color: var(--text-color);
      }
    }
  }

  &__body {
    overflow: hidden;
    padding: var(--unit);
    text-overflow: ellipsis;

    &-text {
      > .is-org-name {
        @include primer(normal, 0);

        color: var(--color-grey--dark);
        > .is-org-disabled {
          @include minion(bold, 0);

          color: var(--warning-color);
        }
      }
    }
  }
}
