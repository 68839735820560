.o-list-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  border-color: var(--border-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &.has-border {
    border: 1px solid var(--border-color);
  }

  &__title {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: var(--unit);
    padding: var(--unit);
    padding-right: calc(0.5 * var(--unit));

    &-text {
      overflow: hidden;

      > :nth-child(1) {
        @include primer(bold, 0);
      }

      > :nth-child(2) {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
      }

      > :nth-child(1),
      > :nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-icon {
      color: var(--color-grey--dark);

      &.is-online {
        color: var(--online-color);
      }

      &.is-offline {
        color: var(--offline-color);
      }

      &.has-bad-connection {
        color: var(--warning-color);
      }

      &.is-success {
        color: var(--success-color);
      }
    }
  }

  &__body {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__overflow {
    /* approx 4 rows */
    max-height: 24ch;
    overflow-y: auto;
    position: relative;
  }

  &__action {
    align-items: center;
    background-color: var(--color-white);
    display: flex;
    margin-top: auto;
    min-height: var(--card-actions-height);
    padding: var(--unit);

    &.has-border {
      border-top: 1px solid var(--border-color);
    }
  }

  &__footer {
    align-items: center;
    border-top: 1px solid var(--border-color);
    display: flex;
    gap: var(--unit);
    margin-top: auto;
    padding: var(--unit);
    padding-right: calc(0.5 * var(--unit));

    &-secondary-text {
      align-items: center;
      color: var(--icon-color);
      display: flex;
      line-height: 1;
      margin-left: auto;
    }
  }
}
