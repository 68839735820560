$action-height: 24px;
$actions-width: 150px;

.o-notification-list {
  list-style: none;
  margin: 0;
  padding: 0;

  /*  individual list item
        */
  &__item {
    @include primer();

    background-color: var(--color-white);
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    display: grid;
    column-gap: var(--unit);
    grid-template-areas:
      'title'
      'tertiary'
      'actions';
    grid-template-columns: 1fr;
    margin: 0;
    padding-block: var(--unit);
    padding-inline: calc(0.5 * var(--unit));
    transition: background-color var(--transition-time);

    @media screen and (min-width: 768px) {
      align-items: center;
      grid-template-areas:
        'title actions'
        'tertiary actions';
      grid-template-columns: auto $actions-width;
    }

    @media screen and (min-width: 1024px) {
      grid-template-areas: 'title tertiary actions';
      grid-template-columns: 2fr 1fr $actions-width;
      row-gap: var(--unit);
    }

    > .is-link {
      display: contents;
    }

    &:hover {
      background-color: var(--color-grey--ultralight);
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.has-icon {
      grid-template-areas:
        'icon title'
        '. tertiary'
        '. actions';
      grid-template-columns: min-content auto;

      @media screen and (min-width: 768px) {
        grid-template-areas:
          'icon title actions'
          'icon tertiary actions';
        grid-template-columns: min-content auto $actions-width;
      }

      @media screen and (min-width: 1024px) {
        grid-template-areas: 'icon title tertiary actions';
        grid-template-columns: min-content 2fr 1fr $actions-width;
      }

      > .is-icon {
        grid-area: icon;

        &.is-offline {
          color: var(--error-color);
        }

        &.is-warning,
        &.is-connectivity {
          color: var(--warning-color);
        }
      }
    }
  }

  &__primary-secondary {
    grid-area: title;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 768px) {
      align-items: center;
      display: flex;
    }

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > :nth-child(1) {
      font-weight: var(--bold-font-weight);
    }

    > :nth-child(2) {
      @include brevier(normal, 0);

      @media screen and (min-width: 768px) {
        @include primer(normal, 0);
      }

      &:before {
        @media screen and (min-width: 768px) {
          color: var(--color-grey--dark);
          content: '|';
          display: inline-block;
          margin-inline: calc(0.5 * var(--unit));
          top: 0;
        }
      }
    }
  }

  &__tertiary {
    @include brevier(normal, 0);

    color: var(--color-grey--dark);
    grid-area: tertiary;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__actions {
    display: flex;
    gap: var(--unit);
    grid-area: actions;
    height: 100%;
    margin-left: auto;
    margin-top: calc(0.5 * var(--unit));
    position: relative;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-top: initial;
      width: initial;
    }

    @media (pointer: coarse) and (max-width: 767px) {
      justify-content: space-between;
    }

    @media screen and (min-width: 768px) {
      align-items: center;
      height: initial;
    }

    > .is-icon {
      color: var(--icon-color);
    }

    > .is-alert-type {
      @include brevier(normal, 0);

      border-radius: calc(2 * var(--unit));
      border-style: solid;
      border-width: 1px;
      color: var(--text-color);
      display: block;
      height: $action-height;
      line-height: 1;
      padding-block: calc(0.25 * var(--unit));
      padding-inline: calc(0.5 * var(--unit));
      text-align: right;
      text-transform: uppercase;

      &.is-offline {
        background-color: var(--color-red--light);
        border-color: var(--color-red);
      }

      &.is-connectivity {
        background-color: var(--color-orange--light);
        border-color: var(--color-orange);
      }
    }

    > .is-action {
      color: transparent;
      cursor: pointer;
      height: $action-height;
      position: absolute;
      right: 0;
      transition: color var(--transition-time);

      @media (pointer: coarse) {
        color: var(--color-grey--dark);
        position: initial;
        right: var(--unit);
        top: var(--unit);
      }

      @media (pointer: coarse) and (max-width: 767px) {
        text-align: right;
      }

      > .is-notification-values {
        display: block;
        opacity: 1;
        transition: opacity var(--transition-time);

        @at-root {
          .o-notification-list__item .is-notification-values {
            opacity: 1;
            transition: var(--transition-time);
          }
          .o-notification-list__item:hover .is-notification-values {
            opacity: 0;
          }
        }
      }

      > .is-delete,
      > .is-edit {
        color: transparent;
        transition: color var(--transition-time);

        @media (pointer: coarse) {
          color: var(--color-grey--dark);
        }

        @at-root {
          .o-notification-list__item:hover .is-action > .is-delete,
          .o-notification-list__item:hover .is-action > .is-edit {
            color: var(--icon-color);
          }
        }
      }

      > .is-delete:hover {
        color: var(--error-color);
      }

      > .is-edit:hover {
        color: var(--color-primary);
      }
    }
  }
}
