.o-chip {
  &__list {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    gap: calc(0.5 * var(--unit));
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    @include brevier(normal, 0);

    background-color: transparent;
    border: 1px solid var(--color-primary);
    border-radius: calc(2 * var(--unit));
    color: var(--text-color);
    cursor: pointer;
    height: 24px;
    line-height: 1;
    min-width: 8ch;
    padding-bottom: calc(0.25 * var(--unit));
    padding-top: calc(0.25 * var(--unit));
    padding-left: calc(0.5 * var(--unit));
    padding-right: calc(0.5 * var(--unit));
    text-align: center;
    transition: all var(--transition-time);

    &.is-warning {
      background-color: var(--warning-color--light);
      border-color: var(--warning-color);
    }

    &.is-error,
    &.is-alert {
      background-color: var(--error-color--light);
      border-color: var(--error-color);
    }

    &:hover,
    &.item-selected {
      background-color: var(--color-primary);
      color: var(--color-white);
    }

    &.all-item-selected {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      color: var(--color-white);
    }
  }
}
