.o-check-label {
  @include brevier(normal, 0);
  border-radius: 2rem;
  border-style: solid;
  border-width: 1px;
  margin-left: var(--unit);
  padding-inline: calc(0.5 * var(--unit));

  &.is-connection {
    background-color: var(--color-orange--light);
    border-color: var(--color-orange);
  }

  &.is-offline {
    background-color: var(--color-red--light);
    border-color: var(--color-red);
  }
}
