@import 'breakpoints';

.o-scroll-wrap {
  display: flex;
  position: relative;
  transform: translateZ(0);

  &.has-left-scroll::before {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    left: 0;
    width: calc(2 * var(--unit));
  }

  &.has-right-scroll::after {
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 23%, rgba(255, 255, 255, 0) 100%);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    width: calc(2 * var(--unit));
  }

  .scroll-left {
    left: -10px;

    @include smMax {
      left: 10px;
    }
  }

  .scroll-right {
    right: -10px;

    @include smMax {
      right: 10px;
    }
  }

  .scroll-icon {
    bottom: 50%;
    position: fixed;
    z-index: 10;
    color: var(--color-white);
    background-color: var(--color-primary);
    padding: calc(var(--unit) * 0.5);
    border-radius: 50%;
    line-height: 0px;
    opacity: 0.3;
    cursor: pointer;

    &.is-small {
      padding: calc(var(--unit) * 0.25);
    }

    &:hover {
      opacity: 0.7;
    }
  }
}
