:root .mat-calendar,
:root .mat-calendar-body-cell,
:root .mat-calendar-period-button {
  font-family: var(--regular-font) !important;
}

:root .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px var(--color-black);
}

:root
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-comparison-identical) {
  background-color: var(--color-primary--ultralight-hover) !important;
}

:root .mat-calendar-body-cell:not(.mat-calendar-body-in-range) > .mat-calendar-body-selected,
:root .mat-calendar-body-in-range > .mat-calendar-body-cell-content {
  background-color: var(--color-primary--ultralight);
}
