$avatar-size: calc(2 * var(--unit));

.o-avatar {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    align-items: center;
    background-color: transparent;
    border-radius: var(--border-radius);
    display: flex;
    gap: var(--unit);
    padding: var(--unit);
    width: 100%;
    transition: background-color var(--transition-time);

    &:hover {
      background-color: var(--color-grey--ultralight);
    }

    > .is-remove {
      align-items: center;
      background-color: transparent;
      border-radius: $avatar-size;
      color: var(--color-grey--dark);
      cursor: pointer;
      display: flex;
      height: $avatar-size;
      justify-content: center;
      margin-left: auto;
      transition: background-color var(--transition-time);
      width: $avatar-size;

      &:hover {
        background-color: var(--border-color);
      }
    }
  }

  &__icon {
    @include brevier(normal, 0);
    align-items: center;
    background-color: var(--color-primary);
    border-radius: $avatar-size;
    color: var(--color-white);
    display: flex;
    flex-shrink: 0;
    height: $avatar-size;
    justify-content: center;
    margin-right: var(--unit);
    text-transform: uppercase;
    width: $avatar-size;

    @at-root {
      .o-avatar__row {
        &:nth-of-type(1n) .o-avatar__icon {
          background-color: var(--color-mikado);
        }
        &:nth-of-type(2n) .o-avatar__icon {
          background-color: var(--color-violet);
        }
        &:nth-of-type(3n) .o-avatar__icon {
          background-color: var(--color-turquoise);
        }
        &:nth-of-type(4n) .o-avatar__icon {
          background-color: var(--color-zomp);
        }
        &:nth-of-type(5n) .o-avatar__icon {
          background-color: var(--color-pear);
        }
        &:nth-of-type(6n) .o-avatar__icon {
          background-color: var(--color-rose);
        }
        &:nth-of-type(7n) .o-avatar__icon {
          background-color: var(--color-apple);
        }
        &:nth-of-type(8n) .o-avatar__icon {
          background-color: var(--color-midnight);
        }
        &:nth-of-type(9n) .o-avatar__icon {
          background-color: var(--color-orchid);
        }
      }
    }
  }

  &__description {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    & > :nth-child(1) {
      @include primer(normal, 0);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > :nth-child(2) {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
