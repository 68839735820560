.mat-expansion-panel-header,
.mat-expansion-panel-content {
  font-family: var(--regular-font);
}

:root {
  .mat-accordion {
    .mat-expansion-panel {
      box-shadow: none;
      transition: all var(--transition-time) cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        box-shadow:
          0 2px 4px rgba(0, 0, 0, 0.18),
          0 1px 2px rgba(0, 0, 0, 0.12);
      }
      &:first-of-type {
        border-radius: var(--border-radius);
      }
      border-radius: var(--border-radius) !important;
    }
  }

  .mat-expansion-panel-body {
    padding-bottom: var(--unit);
    padding-left: var(--unit);
    padding-right: var(--unit);
    padding-top: 0;
  }

  .mat-expansion-panel-header {
    border-bottom: 1px solid transparent;
    border-radius: initial;
    padding-left: var(--unit);
    padding-right: var(--unit);

    @at-root {
      .mat-expanded > .mat-expansion-panel-header {
        border-bottom-color: var(--border-color);
      }
    }
  }
}
