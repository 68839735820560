.o-slider-legend {
  align-items: center;
  display: flex;
  justify-content: space-between;

  > :nth-child(1) {
    @include primer(bold, 0);
  }

  > :nth-child(2) {
    @include primer(normal, 0);

    color: var(--color-grey--dark);
  }
}
