.o-form-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 1600px;

  &__item {
    flex: 1;
    padding: calc(0.5 * var(--unit));
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: var(--unit);
    }

    @media screen and (min-width: 1024px) {
      padding: calc(2 * var(--unit));
    }

    &.is-2-col {
      @media screen and (min-width: 1024px) {
        display: flex;
      }

      > :nth-child(1) {
        border-bottom: 1px solid var(--border-color);
        display: block;
        margin-bottom: calc(2 * var(--unit));
        padding-bottom: calc(2 * var(--unit));

        @media screen and (min-width: 1024px) {
          align-items: center;
          border-bottom: initial;
          display: flex;
          margin-bottom: initial;
          padding-bottom: initial;
          width: calc(50% + 2 * var(--unit));
        }

        &:after {
          @media screen and (min-width: 1024px) {
            background-color: var(--border-color);
            content: '';
            display: block;
            height: 100%;
            margin-left: calc(2 * var(--unit));
            margin-right: calc(2 * var(--unit));
            width: 1px;
          }
        }
      }

      > :nth-child(2) {
        border-bottom: 1px solid var(--border-color);
        display: block;
        margin-bottom: calc(2 * var(--unit));
        padding-bottom: calc(2 * var(--unit));

        @media screen and (min-width: 1024px) {
          border-bottom: initial;
          margin-bottom: initial;
          padding-bottom: initial;
          width: calc(50% - 2 * var(--unit));
        }
      }
    }
  }

  &__action {
    border-bottom: 1px solid var(--border-color);
    display: flex;
    justify-content: flex-end;
    padding-block-end: var(--unit);
  }
}
