.c-inline-icon-text {
  align-items: center;
  display: flex;
  gap: var(--unit);

  @media screen and (min-width: 768px) {
    gap: calc(2 * var(--unit));
  }

  &.is-muted {
    color: var(--color-grey--dark);
  }

  &.is-light {
    color: var(--color-white);
  }

  .is-muted {
    color: var(--color-grey--dark);
  }
}
