.mat-mdc-form-field {
  font-family: var(--regular-font);
}

.mat-mdc-form-field-icon-sufix,
.mat-mdc-form-field-icon-prefix {
  svg,
  .mat-icon {
    color: var(--icon-color);
    fill: var(--icon-color);
    font-size: 24px !important;
  }
}
