.u-island {
  padding: var(--unit);

  &.is-large {
    @media screen and (min-width: 768px) {
      padding: calc(2 * var(--unit));
    }
  }
}

.u-flush {
  margin: 0 !important;

  &--bottom {
    margin-bottom: 0 !important;
  }

  &--right {
    margin-right: 0 !important;
  }

  &--left {
    margin-left: 0 !important;
  }
}

.u-flush-padding {
  padding: 0 !important;

  &--bottom {
    padding-bottom: 0 !important;
  }

  &--right {
    padding-right: 0 !important;
  }
}

.u-hard {
  padding: 0 !important;

  &--left {
    padding-left: 0 !important;
  }

  &--right {
    padding-right: 0 !important;
  }
}

.u-margin {
  &--bottom {
    margin-bottom: var(--unit) !important;
  }

  &--left {
    margin-left: var(--unit) !important;
  }

  &--right {
    margin-right: var(--unit) !important;
  }

  &--top {
    margin-top: var(--unit) !important;
  }
  &--title {
    margin: var(--unit);
  }
}

.u-padding {
  &--bottom {
    padding-bottom: var(--unit) !important;
  }

  &--left {
    padding-left: var(--unit) !important;
  }

  &--right {
    padding-right: var(--unit) !important;
  }

  &--top {
    padding-top: var(--unit) !important;
  }

  &--block {
    padding-block-end: var(--unit) !important;
    padding-block-start: var(--unit) !important;
  }

  &--inline {
    padding-inline-end: var(--unit) !important;
    padding-inline-start: var(--unit) !important;
  }
  &--title {
    padding: var(--unit);
  }
}

.u-border {
  &--bottom {
    border-bottom: 1px solid var(--border-color);
  }
}

.u-border-radius {
  border-radius: var(--border-radius);
}

.u-capitalize {
  text-transform: uppercase;
}

.u-display-contents {
  display: contents !important;
}

.u-text--light {
  color: var(--color-grey--dark) !important;
}

.u-caps {
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

// background-color
.u-grey-bg {
  background-color: var(--color-grey--ultralight) !important;
}

.u-orange-bg {
  background-color: var(--color-orange) !important;
}

.u-yellow-bg {
  background-color: var(--color-yellow) !important;
}

.u-orange-light-bg {
  background-color: var(--color-orange--light) !important;
}

.u-success-bg {
  background-color: var(--color-apple--light-1) !important;
}

.u-primary-bg {
  background-color: var(--color-primary) !important;
}

.u-red-bg {
  background-color: var(--color-red) !important;
}

.u-red-light-bg {
  background-color: var(--color-red--light) !important;
}

.u-white-bg {
  background-color: var(--color-white) !important;
}

// color
.u-grey-color {
  color: var(--color-grey--dark) !important;
}

.u-black-color {
  color: var(--color-black) !important;
}

.u-orange-color {
  color: var(--color-orange) !important;
}

.u-primary-color {
  color: var(--color-primary) !important;
}

.u-red-color {
  color: var(--color-red) !important;
}

.u-secondary-color {
  color: var(--color-secondary) !important;
}

.u-white-color {
  color: var(--color-white) !important;
}

.u-hide {
  visibility: hidden !important;
}

.u-hide-mobile {
  @media screen and (max-width: 767px) {
    display: none !important;
    visibility: hidden !important;
  }
}

.u-hide-tablet {
  @media screen and (max-width: 1023px) {
    display: none !important;
    visibility: hidden !important;
  }
}
