/* ------------------------------------------------------------------------- *\
        Banner alert is a slim notification that can be used at the
        top of a page.

        Do set a type of .is-success, is-error, is-warning
        Do use an icon.
        Optionally have an action button (such as close/dismiss this banner).
            -   this close button should be a simple 'x' icon positioned
                at far right of banner

        Banner text can include a link.
\* ------------------------------------------------------------------------- */

.o-banner-alert {
  background-color: var(--color-grey--ultralight);
  color: var(--text-color);
  display: flex;
  gap: var(--unit);
  margin-bottom: var(--unit);
  padding: calc(var(--unit) * 0.125) calc(var(--unit) * 0.5);

  @media screen and (min-width: 768px) {
    align-items: center;
    gap: calc(2 * var(--unit));
  }

  &.is-primary {
    background-color: var(--color-primary--ultralight);

    > .is-icon {
      color: var(--color-primary);
    }

    > .is-badge {
      background-color: var(--color-primary);
    }
  }

  &.is-success {
    background-color: var(--success-color--light);

    > .is-icon {
      color: var(--success-color);
    }

    > .is-badge {
      background-color: var(--success-color);
    }
  }

  &.is-confirmation {
    background-color: var(--success-color);

    > .is-icon {
      color: var(--color-white);
    }

    > .is-message {
      color: var(--color-white);
    }
  }

  &.is-warning {
    background-color: var(--warning-color--light);

    > .is-icon {
      color: var(--warning-color);
    }

    > .is-badge {
      background-color: var(--warning-color);
    }
  }

  &.is-error,
  &.has-error {
    background-color: var(--error-color--light);

    > .is-icon {
      color: var(--error-color);
    }

    > .is-badge {
      background-color: var(--error-color);
    }
  }

  > .is-badge {
    align-items: center;
    background-color: var(--color-grey--dark);
    border-radius: var(--border-radius);
    color: var(--color-white);
    display: flex;
    gap: calc(0.5 * var(--unit));
    height: calc(2 * var(--unit));
    padding-inline: calc(0.5 * var(--unit));
    width: max-content;
  }

  &.is-slim {
    gap: var(--unit);
    padding-block: calc(0.5 * var(--unit));

    > .is-message {
      @include brevier(normal, 0);
    }
  }

  > .is-message {
    @include brevier(normal, 0);

    @media screen and (min-width: 768px) {
      @include primer(normal, 0);
    }

    &.has-flex {
      @media screen and (min-width: 768px) {
        align-items: center;
        display: flex;
      }
    }
  }

  $action-size: 40px;

  > .is-action {
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    color: var(--color-grey--dark);
    cursor: pointer;
    display: flex;
    height: $action-size;
    justify-content: center;
    margin-left: auto;
    overflow: hidden;
    transition: background-color var(--transition-time);
    width: $action-size;

    &:hover {
      background-color: var(--color-grey--ultralight);
    }
  }

  &.no-gap {
    gap: unset;
  }
}
