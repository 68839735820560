$button-size: 36px;

.o-modal-full-page {
  background-color: var(--color-white);
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  min-height: 100vh;
  overflow: auto;

  &.with-neutral-background {
    background-color: var(--color-neutral);
  }

  &.is-not-full-height {
    min-height: unset;
  }

  &.has-secondary-header {
    grid-template-rows: min-content min-content auto;
  }

  &.has-no-header {
    grid-template-rows: auto;
  }

  &__header {
    align-items: center;
    background-color: var(--primary-color);
    display: flex;
    height: var(--header-height);
    overflow: hidden;
    padding-left: var(--unit);
    padding-right: var(--unit);
    width: 100%;
    z-index: 9;

    > .is-close {
      @include primer(normal, 0);

      align-items: center;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      color: var(--color-white);
      cursor: pointer;
      display: flex;
      height: $button-size;
      font-size: 1rem;
      justify-content: center;
      margin: 0;
      outline: none;
      overflow: hidden;
      padding: 0;
      transition: background-color var(--transition-time);
      width: $button-size;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    > .is-action {
      @include primer(normal, 0);

      align-items: center;
      background-color: transparent;
      border: 1px solid var(--color-white);
      border-radius: var(--border-radius);
      color: var(--color-white);
      cursor: pointer;
      display: flex;
      height: $button-size;
      line-height: 1;
      text-transform: uppercase;
      margin-left: auto;
      outline: none;
      padding: 0;
      padding-left: var(--unit);
      padding-right: var(--unit);
      position: relative;
      text-align: right;
      transition: all var(--transition-time);
      width: max-content;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-color: var(--color-primary);
      }

      &:disabled,
      &[disabled] {
        background-color: transparent !important;
        border: 1px solid var(--color-white) !important;
        cursor: initial !important;
        opacity: 0.6 !important;
      }
    }

    > .is-title {
      @include long-primer(700, 0);

      color: var(--color-white);
      margin: 0 var(--unit);
      overflow: hidden;
      text-overflow: ellipsis;
      width: max-content;
      white-space: nowrap;
    }
  }

  &__actions {
    padding: var(--unit) var(--unit) 0;
    width: 100%;
  }

  &__body {
    align-items: center;
    height: 100%;
    overflow: auto;
    margin-inline: auto;
    padding-bottom: calc(2 * var(--unit));
    padding-top: var(--unit);
    width: 100%;
    z-index: 1;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      padding-top: calc(2 * var(--unit));
    }

    .main-content {
      color: var(--color-black);
      display: grid;
      gap: calc(2 * var(--unit));
      padding-inline: calc(0.5 * var(--unit));
      max-width: 100%;
      width: var(--constrained-dialog-width);

      &.is-large {
        width: var(--constrained-large-dialog-width);
      }

      &__title {
        display: flex;
        flex-direction: row;

        &-icon {
          color: var(--icon-color);
          padding: calc(var(--unit) / 2) var(--unit);
        }

        &-text {
          > .is-primary {
            @include pica(bold, 0);
          }

          > .is-secondary {
            @include brevier(normal, 0);
          }
        }
      }

      &__text {
        @include brevier(normal, 0);
        align-items: center;
        display: flex;

        &.secondary {
          color: var(--color-grey--dark);

          &.has-padding-left {
            padding-left: calc(2rem + 24px);
          }
        }

        &.error {
          color: var(--color-grey--dark);
          background-color: var(--color-red--light);
          padding: calc(var(--unit) / 2) var(--unit);

          > .is-left-icon {
            color: var(--color-red);
            margin-right: var(--unit);
          }
        }

        &.informative {
          background-color: var(--color-grey--ultralight);
          padding: calc(var(--unit) / 2) var(--unit);

          > .is-right-icon {
            color: var(--icon-color);
            margin-left: auto;

            &.is-pointer {
              cursor: pointer;
            }
          }
        }

        &.warning {
          background-color: var(--color-orange--light);
          padding: calc(var(--unit) / 2) var(--unit);

          > .is-left-icon {
            color: var(--color-orange);
            margin-right: var(--unit);
          }
        }

        &.success {
          background-color: var(--color-secondary--light);
          padding: calc(var(--unit) / 2) var(--unit);

          > .is-left-icon {
            color: var(--color-secondary--darker);
            margin-right: var(--unit);
          }
        }

        &.link {
          background-color: var(--color-white);
          padding: calc(var(--unit) / 2) var(--unit);

          > .is-left-icon {
            color: var(--color-primary);
            margin-right: var(--unit);
          }
        }
      }
    }
  }

  &__footer {
    border-top: 1px solid var(--border-color);
    display: flex;
    height: 48px;
    margin-top: auto;

    > .is-action {
      @include primer(normal, 0);

      align-items: center;
      border-radius: 0;
      display: flex;
      cursor: pointer;
      flex: 1;
      justify-content: center;
      color: var(--color-primary);
      transition: color var(--transition-time);

      &:hover {
        color: var(--color-primary--darker);
      }

      &.is-uppercase {
        text-transform: uppercase;
      }
    }
  }
}
