.o-card-grid {
  display: grid;
  gap: var(--unit);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin-bottom: var(--unit);

  @media screen and (min-width: 768px) {
    gap: calc(2 * var(--unit));
    margin-bottom: calc(2 * var(--unit));
  }

  &.has-border-bottom {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: var(--unit);
    padding-bottom: var(--unit);
  }

  &--span2 {
    @media screen and (min-width: 768px) {
      grid-column-end: 3;
      grid-column-start: 1;
    }
  }
}
