.o-expansion-section {
  margin-bottom: calc(var(--unit) * 2);

  @include lgMax {
    margin-bottom: var(--unit);
  }

  &__header {
    align-items: center;
    color: var(--color-white);
    background-color: var(--color-primary);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    display: flex;
    padding: var(--unit);

    > .is-icon {
      margin-right: var(--unit);
    }

    > .is-title {
      @include primer(bold, 0);
    }

    > .is-expansion-icon {
      margin-left: auto;

      @include lgMin {
        display: none;
      }
    }
  }

  &__body {
    background-color: var(--color-white);
    padding: var(--unit);
    max-height: 1000px;

    &.is-hidden {
      height: 0;
      padding: 0;
      overflow: hidden;

      @include lgMin {
        padding: var(--unit);
        height: auto;
      }
    }
  }
}
