/* ----------------------------------------------------------------------- *\
<ul class="o-long-checklist">
    <li class="c-checklist__item">
        <mat-checkbox class="is-checkbox is-select-all">
            Select all
        </mat-checkbox>
    </li>
    <li class="c-checklist__item">
    <mat-checkbox class="is-checkbox">
        Label
    </mat-checkbox>
    </li>
</ul>
\* ----------------------------------------------------------------------- */

.o-long-checklist {
  background-color: inherit;
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    border-bottom: 1px solid var(--border-color);
    margin: 0;
    padding: 0;

    &.has-hover {
      cursor: pointer;
      transition: var(--transition-time);

      &:hover {
        background-color: var(--color-primary--ultralight-hover);
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  .is-checkbox {
    align-items: center;
    background-color: transparent;
    display: flex;
    flex: 1;
    font-weight: 700;
    height: 44px;
    margin: 0;
    padding-inline-start: calc(0.5 * var(--unit));
    transition: background-color var(--transition-time);

    &:hover {
      background-color: var(--color-grey--ultralight);
    }

    &.is-select-all {
      border-top: 1px solid var(--border-color);
      font-weight: 400;
      height: unset;
      padding-block: var(--unit);
    }
  }
}
