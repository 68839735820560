/* COLOURS */

body {
  --color-primary: hsla(214, 100%, 52%, 1);
  --color-primary--darker: hsla(214, 100%, 40%, 1);
  --color-primary--ultralight: hsla(214, 100%, 92%, 1);
  --color-primary--ultralight-2: hsla(214, 100%, 98%, 1);
  --color-primary--ultralight-hover: hsla(214, 100%, 97%, 1);
  --color-black: hsla(214, 10%, 10%, 1);
  --color-grey--ultralight: hsla(214, 10%, 95%, 1);
  --color-grey--light: hsla(214, 10%, 90%, 1);
  --color-grey--medium: hsla(214, 10%, 80%, 1);
  --color-grey--medium-darker: hsla(214, 10%, 70%, 1);
  --color-grey--dark: hsla(214, 10%, 50%, 1);

  --color-transparent-grey--dark: hsla(214, 10%, 50%, 0.2);

  --color-secondary: hsla(156, 78%, 43%, 1);
  --color-secondary--light: hsla(156, 60%, 91%, 1);
  --color-secondary--darker: hsla(156, 78%, 38%, 1);

  --color-red: hsla(354, 82%, 59%, 1);
  --color-red--light: hsla(354, 100%, 92%, 1);

  --color-orange: hsla(19, 100%, 65%, 1);
  --color-orange--light: hsla(20, 100%, 90%, 1);

  --color-neutral: hsla(35, 33%, 93%, 1);

  --color-true-black: hsla(0, 0%, 0%, 1);
  --color-white: hsla(0, 100%, 100%, 1);

  --color-yellow: hsla(52, 100%, 58%, 1);
  --color-yellow--light: hsla(44, 100%, 90%, 1);

  /* SECONDARY COLOURS  / REPORTING COLOURS */
  --color-mikado: rgba(253, 192, 11, 1);
  --color-mikado--light-1: rgb(254, 212, 88);
  --color-mikado--light-2: rgb(254, 224, 133);
  --color-mikado--light-3: rgb(255, 239, 195);
  --color-violet: rgba(63, 80, 180, 1);
  --color-violet--light: rgba(118, 126, 176, 1);
  --color-violet--ultralight: rgb(234, 235, 239);
  --color-turquoise: rgba(66, 204, 221);
  --color-turquoise--light: rgb(245, 251, 253);
  --color-turquoise--light-1: rgb(241, 246, 248);
  --color-zomp: rgba(45, 148, 151);
  --color-pear: rgba(205, 217, 57);
  --color-rose: rgba(228, 21, 111);
  --color-rose--light: rgba(241, 138, 184);
  --color-apple: rgba(73, 194, 78);
  --color-apple--light-1: rgb(119, 210, 123);
  --color-apple--light-2: rgb(166, 225, 167);
  --color-apple--light-3: rgb(210, 240, 211);
  --color-midnight: rgba(12, 62, 86);
  --color-midnight--light-1: rgb(74, 111, 129);
  --color-orchid: rgba(155, 38, 175);

  /* utility colors */
  --error-color: var(--color-red);
  --error-color--light: var(--color-red--light);
  --error-color--light-hover: hsla(354, 100%, 88%, 1);
  --success-color: var(--color-secondary);
  --success-color--light: var(--color-secondary--light);
  --warning-color: var(--color-orange);
  --warning-color--light: var(--color-orange--light);
  --text-color: var(--color-black);
  --disabled-color: var(--color-grey--medium);
  --border-color: var(--color-grey--medium);
  --link-color: var(--color-primary);
  --link-color--hover: var(--color-primary--darker);
  --online-color: var(--color-primary);
  --offline-color: var(--error-color);
  --poor-connection-color: var(--color-orange);
  --icon-color: var(--color-grey--dark);
  --alert-color: var(--color-secondary);

  /* DESIGN TOKENS */
  /* BASE */
  --unit: 1rem;
  --regular-font: 'IBM Plex Sans';
  --regular-font-weight: 400;
  --heading-font: 'IBM Plex Sans';
  --bold-font-weight: 700;

  --border-radius: 3px;
  --border-radius--small: 2px;
  --border-radius--medium: 4px;
  --border-radius--large: 6px;

  --button-height: 36px;
  --transition-time: 0.6s;

  --card-background-color: var(--color-white);
  --card-border-radius: var(--border-radius--medium);
  --card-actions-height: 48px;

  --direction-unit: 60px;

  --header-height: 48px;
  --nav-height: 36px;
  --footer-bar-height: 60px;

  --constrained-width: 1024px;
  --constrained-dialog-width: 30rem;
  --constrained-large-dialog-width: 40rem;

  @media screen and (min-width: 768px) {
    --header-height: 60px;
    --nav-height: 48px;
  }

  /*  Scrollbar styles */
  --scrollbar-background: var(--color-grey--light);
  --scrollbar-border: 1px solid var(--color-white);
  --scrollbar-radius: 10px;
  --scrollbar-width: 6px;
  --scrollbar-height: 6px;
  --scrollbar-thumb-background: var(--color-grey--medium-darker);
}
