.o-feature-card {
  background-color: var(--color-primary);
  border-radius: var(--card-border-radius);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__body {
    padding-block: var(--unit);
  }

  &__head {
    align-items: center;
    border-bottom: 1px solid var(--color-white);
    display: flex;
    gap: calc(2 * var(--unit));
    padding: var(--unit);

    > .is-title {
      > :nth-child(1) {
        @include long-primer(bold, 0);

        color: var(--color-white);
      }

      > :nth-child(2) {
        @include brevier(normal, 0);

        color: var(--color-white);
      }

      &.is-reverse {
        > :nth-child(1) {
          @include brevier(normal, 0);
        }

        > :nth-child(2) {
          @include long-primer(bold, 0);
        }
      }
    }
  }

  &__action {
    align-items: center;
    border-top: 1px solid var(--color-white);
    color: var(--color-white);
    cursor: pointer;
    display: flex;
    height: var(--card-actions-height);
    justify-content: flex-end;
    margin-top: auto;
    padding-inline-end: var(--unit);

    &.has-no-border {
      border: 0;
    }
  }

  &__list-item {
    > .is-link {
      @include long-primer(normal, 0);

      align-items: center;
      background-color: transparent;
      color: var(--color-white);
      cursor: pointer;
      display: flex;
      gap: calc(2 * var(--unit));
      padding: var(--unit);
      transition: background-color var(--transition-time);

      .is-sub-text {
        @include brevier(normal, 0);
        display: flex;
        flex-direction: column;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }

    .is-small & {
      > .is-link {
        @include brevier(normal, 0);
      }
    }
  }

  &__separating_line {
    border-top: 1px solid var(--color-white);
  }
}
