/*  override styles for mat-timepicker.
 *  don't look too closely, these are horrible hacks
 *  to force specificity
 */
:root {
  .timepicker {
    &-overlay,
    &-backdrop-overlay {
      z-index: 10000000 !important;
    }

    &-dial__time {
      font-family: var(--regular-font) !important;
    }

    &-dial__control {
      font-family: var(--regular-font) !important;
    }

    &-dial__item_active {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    &__header {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    .clock-face__number > span.active,
    .clock-face__clock-hand,
    &__header {
      background-color: var(--color-primary) !important;
    }

    &-button {
      color: var(--color-primary) !important;
      font-family: var(--regular-font);

      > span {
        font-family: var(--regular-font) !important;
        font-size: var(--unit) !important;
        font-weight: normal !important;
      }
    }

    .clock-face {
      background-color: var(--color-grey--ultralight);

      &__number > span {
        color: var(--text-color) !important;
        font-family: var(--regular-font) !important;

        &.active {
          color: var(--color-white) !important;
        }
      }
    }

    .clock-face__clock-hand_minute {
      &:before {
        border-color: var(--color-primary) !important;
      }
    }
  }
}
