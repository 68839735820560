.o-banner-message {
  align-items: center;
  display: flex;
  gap: var(--unit);
  justify-content: center;
  padding: var(--unit);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__text {
    > .is-title {
      padding-bottom: var(--unit);
      width: 100%;

      &.is-primer {
        @include primer(bold, 0);
      }

      &.is-pica {
        @include pica(bold, 0);
      }

      &.is-paragon {
        @include paragon(bold, 0);
      }
    }

    > .is-text {
      @include brevier();
    }
  }

  &__media {
    align-items: center;
    display: flex;
    flex-shrink: 0;

    &.is-small {
      width: 33.3%;
    }

    .is-grayscale {
      filter: grayscale(100%);
    }
  }

  &.background-grey {
    background-color: var(--color-grey--ultralight);
  }
}
