@mixin minion($weight: 400, $margin: 1rem) {
  font-size: 0.75rem;
  font-weight: $weight;
  margin-bottom: $margin;
}

@mixin brevier($weight: 400, $margin: 1rem) {
  font-size: 0.875rem;
  font-weight: $weight;
  margin-bottom: $margin;
}

@mixin primer($weight: 400, $margin: 1rem) {
  font-size: 1rem;
  font-weight: $weight;
  margin-bottom: $margin;
}

@mixin long-primer($weight: 400, $margin: 1rem) {
  font-size: 1.125rem;
  font-weight: $weight;
  margin-bottom: $margin;
}

@mixin pica($weight: 700, $margin: 1rem) {
  font-size: 1.375rem;
  font-weight: $weight;
  line-height: 1.2;
  margin-bottom: $margin;
}

@mixin paragon($weight: 700, $margin: 1rem) {
  font-size: 1.875rem;
  font-weight: $weight;
  line-height: 1.2;
  margin-bottom: $margin;
}

@mixin icon-button($color: var(--text-color)) {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  color: $color;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: calc(0.5 * var(--unit));
  transition: background-color var(--transition-time);
  width: 40px;

  &:hover {
    background-color: var(--color-grey--ultralight);
  }
}

@mixin underline($width: 100%, $color: var(--color-primary)) {
  position: relative;

  &:after {
    content: '';
    width: $width;
    height: 3px;
    background: $color;
    position: absolute;
    bottom: -4px;
    left: 0;
  }
}
