@use '@angular/material' as mat;
@import 'https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400&family=IBM+Plex+Sans:ital,wght@0,400;0,700;1,400&display=swap');

@import 'scss/base/reset';
@import 'scss/base/mat-theme';
@import 'scss/base/mixins';
@import 'scss/base/abstracts';
@import 'scss/base/breakpoints';
@import 'scss/base/type-styles';
@import 'scss/objects/forms';
@import 'scss/objects/hr';
@import 'scss/objects/views';
@import 'scss/objects/card-layout-grid';
@import 'scss/objects/cards';
@import 'scss/objects/monitor-card';
@import 'scss/objects/card-monitor-view';
@import 'scss/objects/category-card';
@import 'scss/objects/feature-card';
@import 'scss/objects/user-card';
@import 'scss/objects/list-card';
@import 'scss/objects/lists';
@import 'scss/objects/lists-rounded';
@import 'scss/objects/lists-block';
@import 'scss/objects/notification-list';
@import 'scss/objects/label';
@import 'scss/objects/buttons';
@import 'scss/objects/banner-alert';
@import 'scss/objects/banner-message';
@import 'scss/objects/breadcrumb';
@import 'scss/objects/modal-full-page';
@import 'scss/objects/chip';
@import 'scss/objects/long-checklist';
@import 'scss/objects/headings';
@import 'scss/objects/title';
@import 'scss/objects/dialog';
@import 'scss/objects/flex';
@import 'scss/objects/drawer-nav';
@import 'scss/objects/sliders';
@import 'scss/objects/media';
@import 'scss/objects/snack-bar';
@import 'scss/objects/feature-panel';
@import 'scss/objects/inline-icon-text';
@import 'scss/objects/from-grid';
@import 'scss/objects/content-panel';
@import 'scss/objects/icon-reveal';
@import 'scss/objects/_expansion';
@import 'scss/objects/_chart-palette';
@import 'scss/objects/avatar';
@import 'scss/objects/scroll-buttons';
@import 'scss/objects/expansion-section';

@import 'scss/utilities/utilities';
@import 'scss/utilities/animations';

@import 'scss/mat-theme/mat-radio-group';
@import 'scss/mat-theme/mat-timepicker';
@import 'scss/mat-theme/mat-form-field';
@import 'scss/mat-theme/mat-calendar';
@import 'scss/mat-theme/mat-menu';
@import 'scss/mat-theme/mat-slider';
@import 'scss/mat-theme/mat-expansion';

.auth-overlay {
  width: 100%;
  height: 100%;
  background-color: var(--color-neutral);
  padding: 2rem;
  border-radius: var(--border-radius);

  &.white-overlay {
    background-color: var(--color-white);
  }

  &.ultralight-overlay {
    background-color: var(--color-grey--ultralight);
  }

  &.no-background {
    background-color: inherit;
  }

  &.no-padding {
    padding-top: 0;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.25rem;
  height: 1.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
  border-radius: 50%;
  border: 0.125rem solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
  height: var(--scrollbar-height);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-background);
  border-radius: var(--scrollbar-radius);
  border: var(--scrollbar-border);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-background);
  border-radius: var(--scrollbar-radius);
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  margin: auto;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-primary);
  color: var(--color-primary);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--color-primary);
  }
  50%,
  100% {
    background-color: var(--color-grey--medium);
  }
}

.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow:
    0 -18px 0 0 var(--color-primary),
    12.72984px -12.72984px 0 0 var(--color-primary),
    18px 0 0 0 var(--color-primary),
    12.72984px 12.72984px 0 0 var(--color-grey--medium),
    0 18px 0 0 var(--color-grey--medium),
    -12.72984px 12.72984px 0 0 var(--color-grey--medium),
    -18px 0 0 0 var(--color-grey--medium),
    -12.72984px -12.72984px 0 0 var(--color-grey--medium);
  animation: dotSpin 1.5s infinite linear;
}

@keyframes dotSpin {
  0%,
  100% {
    box-shadow:
      0 -18px 0 0 var(--color-primary),
      12.72984px -12.72984px 0 0 var(--color-primary),
      18px 0 0 0 var(--color-primary),
      12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      0 18px 0 -5px var(--color-grey--medium),
      -12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      -18px 0 0 -5px var(--color-grey--medium),
      -12.72984px -12.72984px 0 -5px var(--color-grey--medium);
  }
  12.5% {
    box-shadow:
      0 -18px 0 -5px var(--color-grey--medium),
      12.72984px -12.72984px 0 0 var(--color-primary),
      18px 0 0 0 var(--color-primary),
      12.72984px 12.72984px 0 0 var(--color-primary),
      0 18px 0 -5px var(--color-grey--medium),
      -12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      -18px 0 0 -5px var(--color-grey--medium),
      -12.72984px -12.72984px 0 -5px var(--color-grey--medium);
  }
  25% {
    box-shadow:
      0 -18px 0 -5px var(--color-grey--medium),
      12.72984px -12.72984px 0 -5px var(--color-grey--medium),
      18px 0 0 0 var(--color-primary),
      12.72984px 12.72984px 0 0 var(--color-primary),
      0 18px 0 0 var(--color-primary),
      -12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      -18px 0 0 -5px var(--color-grey--medium),
      -12.72984px -12.72984px 0 -5px var(--color-grey--medium);
  }
  37.5% {
    box-shadow:
      0 -18px 0 -5px var(--color-grey--medium),
      12.72984px -12.72984px 0 -5px var(--color-grey--medium),
      18px 0 0 -5px var(--color-grey--medium),
      12.72984px 12.72984px 0 0 var(--color-primary),
      0 18px 0 0 var(--color-primary),
      -12.72984px 12.72984px 0 0 var(--color-primary),
      -18px 0 0 -5px var(--color-grey--medium),
      -12.72984px -12.72984px 0 -5px var(--color-grey--medium);
  }
  50% {
    box-shadow:
      0 -18px 0 -5px var(--color-grey--medium),
      12.72984px -12.72984px 0 -5px var(--color-grey--medium),
      18px 0 0 -5px var(--color-grey--medium),
      12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      0 18px 0 0 var(--color-primary),
      -12.72984px 12.72984px 0 0 var(--color-primary),
      -18px 0 0 0 var(--color-primary),
      -12.72984px -12.72984px 0 -5px var(--color-grey--medium);
  }
  62.5% {
    box-shadow:
      0 -18px 0 -5px var(--color-grey--medium),
      12.72984px -12.72984px 0 -5px var(--color-grey--medium),
      18px 0 0 -5px var(--color-grey--medium),
      12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      0 18px 0 -5px var(--color-grey--medium),
      -12.72984px 12.72984px 0 0 var(--color-primary),
      -18px 0 0 0 var(--color-primary),
      -12.72984px -12.72984px 0 0 var(--color-primary);
  }
  75% {
    box-shadow:
      0 -18px 0 0 var(--color-primary),
      12.72984px -12.72984px 0 -5px var(--color-grey--medium),
      18px 0 0 -5px var(--color-grey--medium),
      12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      0 18px 0 -5px var(--color-grey--medium),
      -12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      -18px 0 0 0 var(--color-primary),
      -12.72984px -12.72984px 0 0 var(--color-primary);
  }
  87.5% {
    box-shadow:
      0 -18px 0 0 var(--color-primary),
      12.72984px -12.72984px 0 0 var(--color-primary),
      18px 0 0 -5px var(--color-grey--medium),
      12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      0 18px 0 -5px var(--color-grey--medium),
      -12.72984px 12.72984px 0 -5px var(--color-grey--medium),
      -18px 0 0 -5px var(--color-grey--medium),
      -12.72984px -12.72984px 0 0 var(--color-primary);
  }
}

.o-router-full-page-wrapper {
  position: absolute;
  left: 0;
  z-index: 10;
  height: calc(100vh - var(--header-height));
  // height: -webkit-fill-available;
}

.o-full-page-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  z-index: 10;
  height: calc(100vh - var(--header-height));
  width: 100vw;

  &__body {
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: var(--color-white);
  }
}
