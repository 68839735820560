.o-media {
  align-items: flex-start;
  background-color: var(--color-primary--ultralight-2);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  padding: var(--unit);
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    align-items: center;
    flex-direction: row;
  }

  &.is-contained {
    background-color: var(--color-grey--ultralight);
  }

  &.is-muted {
    background-color: var(--color-grey--ultralight);

    img {
      filter: grayscale(100%);
    }
  }

  &.has-outline {
    border-color: var(--border-color);
    border-style: solid;
    border-width: 1px;
  }

  &.is-primary {
    background-color: var(--color-primary);
    color: var(--color-white);

    * {
      color: var(--white);
    }
  }

  &.is-stacked {
    align-items: flex-start;
    flex-direction: column;
  }

  .is-primary-text {
    @include brevier(normal, 0);
    display: flex;
    align-items: center;

    .is-icon {
      margin-right: var(--unit);

      &.is-warning {
        color: var(--warning-color);
      }
    }
  }
}
