.o-breadcrumb {
  @include brevier(normal, 0);

  align-items: center;
  background-color: var(--color-grey--ultralight);
  display: flex;
  list-style: none;
  margin: 0;
  padding-bottom: calc(0.5 * var(--unit));
  padding-left: var(--unit);
  padding-right: var(--unit);
  padding-top: calc(0.5 * var(--unit));

  &__item {
    align-items: center;
    color: var(--color-grey--dark);
    display: flex;

    &:after {
      color: var(--color-grey--dark);
      content: '/';
      display: block;
      margin-left: calc(0.5 * var(--unit));
      margin-right: calc(0.5 * var(--unit));
    }

    &.is-home:after,
    &:last-of-type:after {
      content: initial;
    }

    &.is-home {
      margin-right: var(--unit);
    }

    .is-icon {
      display: block;
    }
  }

  > .is-link {
    color: var(--color-primary);
    cursor: pointer;
    display: flex;

    > .mat-icon {
      display: block;
    }
  }
}
