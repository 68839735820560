@mixin theme($color: var(--color-black)) {
  .mdc-snackbar__surface {
    background: $color !important;
    margin: 10px !important;
  }

  .mat-mdc-simple-snack-bar .mat-mdc-snack-bar-action,
  .mat-mdc-snack-bar-label .mdc-snackbar__label {
    color: var(--color-black) !important;
  }
}

.snack-bar {
  &-success {
    @include theme($color: var(--success-color));
  }

  &-error {
    @include theme($color: var(--error-color));
  }

  &-warning {
    @include theme($color: var(--warning-color));
  }

  &-neutral {
    @include theme($color: var(--color-secondary));
  }
}

.c-camera-online {
  background-color: var(--color-secondary);

  h4 {
    color: var(--color-white);
    font-weight: bold;
  }

  p {
    color: var(--color-white);
    margin-bottom: 0;
  }
}

.c-camera-offline {
  background-color: var(--color-black);

  h4 {
    color: var(--color-white);
    font-weight: bold;
  }

  p {
    color: var(--color-white);
    margin-bottom: 0;
  }
}
