.o-rule {
  border: none;
  border-top: 1px solid var(--border-color);
  margin-bottom: var(--unit);
  margin-top: var(--unit);
  width: 100%;

  @media screen and (min-width: 768px) {
    margin-bottom: calc(2 * var(--unit));
    margin-top: calc(2 * var(--unit));
  }
}
