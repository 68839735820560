:root .mat-mdc-dialog-container.mat-dialog-container {
  background-color: transparent;
  border-radius: 0;
  padding: 0;
}

.o-dialog {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  height: calc(100% - 1px);
  padding-top: calc(2 * var(--unit));

  &__title {
    padding-block-end: calc(2 * var(--unit));
    padding-inline: calc(2 * var(--unit));

    > :nth-child(1) {
      @include primer(bold, 0);
      white-space: pre-line;
    }

    > :nth-child(2) {
      @include brevier(normal, 0);
      white-space: pre-line;
      color: var(--color-grey--dark);
    }
  }

  &__body {
    padding-block-end: calc(1 * var(--unit));
    padding-inline: calc(1 * var(--unit));
  }

  $action-height: 48px;

  &__actions {
    border-top: 1px solid var(--border-color);
    cursor: pointer;
    display: flex;
    height: $action-height;
    margin-top: auto;

    > :nth-child(1),
    > :nth-child(2) {
      @include primer(normal, 0);

      align-items: center;
      border-radius: 0;
      display: flex;
      flex: 1;
      justify-content: center;
    }

    > :nth-child(2) {
      border-left: 1px solid var(--border-color);
    }

    > .is-destructive {
      color: var(--color-grey--dark);
      transition: color var(--transition-time);

      &:hover {
        color: var(--text-color);
      }

      &.is-uppercase {
        text-transform: uppercase;
      }
    }

    > .is-constructive {
      color: var(--color-primary);
      transition: color var(--transition-time);

      &.is-delete {
        color: var(--error-color);
      }

      &.is-neutral {
        color: var(--color-grey--dark);
      }

      &:hover {
        color: var(--color-primary--darker);
      }

      &.is-uppercase {
        text-transform: uppercase;
      }
    }
  }
}

.o-step-body {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-width: 100%;
  width: calc(100% - 2 * var(--unit));

  /*  not sure the default needs to be align center, but to save some possible 
   *  issues, an optional align left variable
   */
  &.is-aligned-left {
    align-items: start;
  }

  @media screen and (min-width: 1024px) {
    max-width: 1000px;
  }
}

.o-dialog-page {
  z-index: 100;
  width: 100vw;
  height: 100%;
  min-height: unset;
}
