.o-monitor-view {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  overflow: hidden;
  padding-bottom: var(--unit);

  &__image {
    position: relative;

    &-wrap {
      display: inline-block;
      position: relative;

      > svg {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    img {
      display: block;
    }

    .is-online & {
      border-bottom-color: var(--color-primary);
    }

    .is-lagging & {
      border-bottom-color: var(--warning-color);
    }

    .is-offline & {
      border-bottom-color: var(--error-color);
    }
  }

  &__no-image {
    align-items: center;
    background-color: var(--error-color--light);
    display: flex;
    justify-content: center;
    padding-inline: var(--unit);
    padding-top: 75%;
    position: relative;
    width: 100%;

    > .is-message {
      @include brevier(normal, 0);

      position: absolute;
      text-align: center;
      top: calc(50% - 2 * var(--unit));
    }
  }

  &__focus-direction {
    align-items: center;
    background-color: var(--color-white);
    bottom: var(--unit);
    display: flex;
    height: 40px;
    max-width: calc(100% - var(--unit));
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    width: max-content;

    > .is-arrow {
      align-items: center;
      background-color: var(--color-primary);
      color: var(--color-white);
      display: flex;
      height: 100%;
      justify-content: center;
      width: 40px;
    }

    > .is-label {
      @include primer(normal, 0);
      padding-inline-end: var(--unit);
      padding-inline-start: calc(0.5 * var(--unit));
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.is-left {
      bottom: 0;
      left: var(--unit);
      margin-bottom: auto;
      margin-top: auto;
      top: 0;
    }

    &.is-right {
      bottom: 0;
      margin-bottom: auto;
      margin-top: auto;
      right: var(--unit);
      top: 0;
    }

    &.is-top {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: var(--unit);
    }

    &.is-bottom {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      bottom: var(--unit);
    }
  }

  &__title {
    align-items: center;
    display: flex;
    column-gap: var(--unit);
    padding: var(--unit);

    @media screen and (min-width: 768px) {
      column-gap: calc(2 * var(--unit));
    }
  }

  &__status-banner {
    align-items: center;
    background-color: var(--color-grey--ultralight);
    display: flex;
    padding: var(--unit);

    .is-lagging & {
      background-color: var(--warning-color--light);
    }

    .is-offline & {
      background-color: var(--error-color--light);
    }
  }

  &__directions {
    display: flex;
    flex-direction: column;
    gap: var(--unit);
    padding-left: var(--unit);
    padding-right: var(--unit);
  }

  &__direction {
    align-items: center;
    background-color: var(--color-grey--ultralight);
    border-radius: calc(2 * var(--unit));
    display: flex;
    gap: var(--unit);
    height: 40px;
    max-width: 100%;
    overflow: hidden;
    justify-content: space-between;
    padding-left: var(--unit);
    padding-right: var(--unit);

    > .is-label {
      @include brevier(normal, 0);

      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .is-arrow {
      color: var(--icon-color);
    }

    > .is-in-direction {
      color: var(--color-primary);
      font-weight: bold;
    }
  }

  &__action {
    margin-top: var(--unit);

    > .is-action {
      margin-left: auto;
    }
  }
}
