$fontConfig: (
  font-family: 'IBM Plex Sans, sans-serif',
  /*display-4: mat-typography-level(112px, 112px, 300, 'IBM Plex Sans', -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, 'IBM Plex Sans', -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, 'IBM Plex Sans', 0em),
  display-1: mat-typography-level(34px, 40px, 400, 'IBM Plex Sans', 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, 'IBM Plex Sans', 0em),
  title: mat-typography-level(20px, 32px, 500, 'IBM Plex Sans', 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 400, 'IBM Plex Sans', 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, 'IBM Plex Sans', 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, 'IBM Plex Sans', 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, 'IBM Plex Sans', 0.0179em),
  button: mat-typography-level(14px, 14px, 500, 'IBM Plex Sans', 0.0893em),
  caption: mat-typography-level(12px, 20px, 400, 'IBM Plex Sans', 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, 'IBM Plex Sans', 1.5px),*/
);

// Foreground Elements

// Light Theme Text
$dark-text: var(--text-color);
$dark-primary-text: var(--text-color);
$dark-accent-text: var(--color-grey--dark);
$dark-disabled-text: var(--disabled-color);
$dark-dividers: var(--border-color);
$dark-focused: var(--link-color);
$light-text: #ffffff;
$light-primary-text: $light-text;

$mat-light-theme-foreground: (
  /*base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,*/
);

// Dark Theme text
/*$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);*/

$mat-dark-theme-foreground: (
  /*base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),*/
);

// Background config
// Light bg
/*$light-background: #f6f6f6;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#3e3e3e, 20%);
$dark-bg-alpha-4: rgba(#3e3e3e, 0.04);
$dark-bg-alpha-12: rgba(#3e3e3e, 0.12);*/

$mat-light-theme-background: (
  /*background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,*/
);

// Dark bg
/*$dark-background: #3e3e3e;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#f6f6f6, 0.04);
$light-bg-alpha-12: rgba(#f6f6f6, 0.12);*/

// Background palette for dark themes.
$mat-dark-theme-background: (
  /*background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,*/
);

// Compute font config
@include mat.core();

// Theme Config

body {
  --accent-color: var(--color-primary);
  --accent-lighter-color: var(--color-primary);
  --accent-darker-color: var(--color-primary-darker);
  --primary-color: var(--color-primary);
  --primary-lighter-color: var(--color-primary);
  --primary-darker-color: var(--color-primary-darker);
  --text-primary-color: var(--text-color);
  --text-primary-lighter-color: var(--color-grey--dark);
  --text-primary-darker-color: var(--text-color);
  --text-accent-color: var(--text-color);
  --text-accent-lighter-color: var(--color-grey--dark);
  --text-accent-darker-color: var(--color-grey--dark);
  --warn-color: var(--warning-color);
  --warn-lighter-color: var(--warning-color--light);
  --warn-darker-color: var(--error-color);
  --text-warn-color: var(--text-color);
  --text-warn-lighter-color: var(--text-color);
  --text-warn-darker-color: var(--text-color);
}

$mat-primary: (
  main: var(--color-primary),
  lighter: var(--color-primary),
  darker: var(--color-primary-darker),
  200: var(--color-primary-darker),
  // For slide toggle,
  contrast:
    (
      main: var(--color-secondary),
      lighter: var(--color-secondary--light),
      darker: var(--color-secondary--dark),
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: var(--color-primary),
  lighter: var(--color-primary),
  darker: var(--color-primary-darker),
  200: var(--color-primary-darker),
  // For slide toggle,
  contrast:
    (
      main: var(--color-secondary),
      lighter: var(--color-secondary--light),
      darker: var(--color-secondary--dark),
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: var(--warning-color),
  lighter: var(--warning-color--light),
  darker: var(--warning-color),
  200: var(--warning-color),
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);
$altTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
  )
);

// Theme Init
@include mat.all-component-themes($theme);

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}

.mat-icon {
  height: unset !important;
  width: unset !important;
  overflow: visible !important;
}

.mat-icon-svg {
  height: 24px !important;
  width: 24px !important;
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  color: white;
  text-transform: uppercase;

  @at-root {
    :root .mat-mdc-raised-button {
      font-size: 1rem;
    }
  }
}

.mat-dialog-side-menu {
  .mat-mdc-dialog-container {
    overflow-x: hidden;
  }
}

:root {
  // button
  .mat-mdc-button:not(:disabled) {
    color: var(--color-primary);
  }

  .mat-mdc-button[disabled] {
    color: var(--color-grey--dark) !important;
    background-color: var(--color-grey--light) !important;
  }

  // radio-button
  .mat-mdc-radio-button .mdc-form-field > label {
    cursor: pointer;
  }

  // checkbox
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
    border-color: var(--color-primary) !important;
    .mdc-checkbox__checkmark {
      color: var(--color-primary);
    }
  }

  // forms
  .mat-mdc-form-field {
    width: 100%;

    &-label {
      color: var(--color-grey--dark);
    }

    &-outline {
      color: var(--border-color);
    }
  }

  .mat-mdc-paginator .mat-mdc-form-field {
    width: 84px;
  }

  .mat-mdc-form-field-subscript-wrapper:before {
    display: none;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border: unset;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mat-mdc-form-field-focus-overlay {
    background-color: unset;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after:hover {
    background-color: var(--color-primary);
  }

  .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mat-mdc-slide-toggle
    .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active)
    .mdc-switch__handle::after {
    background: var(--color-primary) !important;
  }
  .mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after,
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: var(--color-primary--ultralight) !important;
  }
  .mat-mdc-slider {
    width: 100%;
  }

  ::placeholder {
    color: var(--color-grey--dark);
  }

  .mat-mdc-input-element {
    color: var(--color-black);

    &:disabled {
      color: var(--color-grey--medium-darker);
    }
  }
}

.mat-mdc-tooltip {
  font-size: 0.75rem;
  max-width: unset !important;
  white-space: pre-line;
}

.dialog-stepper {
  height: 100%;

  .mat-horizontal-stepper-header-container {
    display: none;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}

.mat-stepper-horizontal,
.mat-mdc-checkbox {
  font-family: var(--regular-font);
}

.mat-mdc-select-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background-color: var(--color-primary--ultralight);
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: unset !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--primary-color) !important;
}

.mat-mdc-list-option
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: white !important;
}
