.o-form {
  color: var(--color-black);
  display: grid;
  gap: calc(2 * var(--unit));
  // overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  &__title {
    @include pica(700, 0);
  }

  &.is-constrained {
    max-width: 100%;
    padding-inline: calc(0.5 * var(--unit));
    width: var(--constrained-form-width);
  }

  &__legend {
    align-items: center;
    display: grid;
    column-gap: var(--unit);
    grid-template-areas:
      'legend action'
      'support-text .'
      'input input';
    grid-template-columns: 1fr;

    @media screen and (min-width: 768px) {
      column-gap: calc(2 * var(--unit));
    }

    @media screen and (min-width: 1200px) {
      grid-template-areas:
        'legend action'
        'support-text . '
        'input input';
    }

    > .is-legend {
      @include long-primer(bold, 0);

      grid-area: legend;

      &.is-minor {
        @include brevier(normal, 0);
      }
    }

    > .is-action {
      grid-area: action;
    }

    > .is-support-text {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
      grid-area: support-text;
      margin-bottom: var(--unit);
    }

    > .is-input {
      grid-area: input;

      .info-text {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
        margin-bottom: var(--unit);
      }
    }
  }

  &__legend-icon {
    align-items: center;
    display: grid;
    column-gap: var(--unit);
    grid-template-areas:
      'icon legend action'
      '. support-text .'
      'input input input';
    grid-template-columns: min-content 1fr;

    //@media screen and (min-width: 768px) {
    //  column-gap: calc(2 * var(--unit));
    //}

    @media screen and (min-width: 1200px) {
      grid-template-areas:
        'icon legend action'
        '. support-text . '
        '. input input';
    }

    > .is-icon {
      color: var(--icon-color);
      grid-area: icon;
    }

    > .is-legend {
      @include long-primer(bold, 0);

      grid-area: legend;

      &.is-minor {
        @include brevier(normal, 0);
      }
    }

    > .is-action {
      grid-area: action;
    }

    > .is-support-text {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
      grid-area: support-text;
      margin-bottom: var(--unit);
    }

    > .is-input {
      grid-area: input;

      .info-text {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
        margin-bottom: var(--unit);
      }
    }
  }

  &__legend-icon-reverse-support {
    align-items: center;
    display: grid;
    column-gap: var(--unit);
    grid-template-areas:
      '. support-text .'
      '. legend action'
      'input input input';
    grid-template-columns: min-content 1fr;

    @media screen and (min-width: 768px) {
      column-gap: calc(2 * var(--unit));
    }

    @media screen and (min-width: 1200px) {
      grid-template-areas:
        'icon support-text .'
        'icon legend action'
        '. input input';
    }

    > .is-icon {
      color: var(--icon-color);
      grid-area: icon;
    }

    > .is-legend {
      @include long-primer(bold, 0);

      grid-area: legend;

      &.is-minor {
        @include brevier(normal, 0);
      }
    }

    > .is-action {
      grid-area: action;
    }

    > .is-support-text {
      @include brevier(normal, 0);

      color: var(--color-grey--dark);
      grid-area: support-text;
    }

    > .is-input {
      grid-area: input;

      .info-text {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
        margin-bottom: var(--unit);
      }
    }
  }
}

input {
  font-family: var(--regular-font);
}

.o-search__input {
  background-color: var(--color-white);
  max-width: 50ch;
  width: 100%;
  /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
   */
  /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
   */
  ::ng-deep .mat-form-field-flex {
    align-items: center;
  }
  /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
   */
  /*  TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
   */
  ::ng-deep .mat-form-field-infix {
    align-items: center;
    border: none;
  }

  .mat-mdc-input-element::placeholder {
    color: var(--color-grey--dark);
  }
}

.o-toggle {
  /*  for short check/radio selections
   *  be aware this layout will NOT wrap on mobile
   */
  &__row {
    display: flex;
    gap: calc(2 * var(--unit));

    > .is-legend {
      align-items: center;
      color: var(--color-grey--dark);
      display: flex;
      gap: calc(0.5 * var(--unit));
    }
  }

  /*  this layout WILL present inputs in a column on small screens
   */
  &__row-responsive {
    display: flex;
    flex-direction: column;
    gap: var(--unit);

    @media screen and (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: calc(2 * var(--unit));
    }
  }

  &__item {
    align-items: center;
    display: flex;
    gap: calc(0.5 * var(--unit));
  }
}
