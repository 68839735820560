.drawer-nav {
  &__right {
    width: 80vw;

    @media screen and (min-width: 768px) {
      width: 30vw;
    }

    @media screen and (min-width: 1365px) {
      width: 20vw;
    }
  }
}
