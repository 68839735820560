/* ------------------------------------------------------------------------- *\

    <div class="o-card is-online">
        <div class="o-card__title">
            <div class="o-card__title-text">
                <h2 class="is-primary-text">
                    Card Title
                </h2>
                <h3 class="is-secondary-text">
                    Optional secondary line
                </h3>
            </div>
            <mat-icon class="o-card__title-actions">more_vert</mat-icon>
        </div>
        <div class="o-card__body">
            ...
        </div>
        <div class="o-card__actions">
            ...
        </div>
    </div>
\* ------------------------------------------------------------------------- */

.o-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  border-color: var(--border-color);
  border-top-style: solid;
  border-top-width: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &.has-no-border {
    border: none;
  }

  &.is-online {
    border-top-color: var(--online-color);
  }

  &.is-offline {
    border-top-color: var(--offline-color);
  }

  &.has-bad-connection {
    border-top-color: var(--warning-color);
  }

  &.is-paused {
    border-top-color: var(--disabled-color);
  }

  &.is-disabled {
    border-top-color: var(--disabled-color);
  }

  &.is-member {
    border-top-color: var(--border-color);
  }

  &.is-admin {
    border-top-color: var(--color-secondary);
  }

  &__title {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: var(--unit);
    padding: var(--unit);
    padding-right: calc(0.5 * var(--unit));

    &.has-no-border {
      border: none;
    }

    $avatar-size: 40px;

    &-avatar {
      align-items: center;
      background-color: var(--color-grey--dark);
      border-radius: 50%;
      color: var(--color-white);
      display: flex;
      flex-shrink: 0;
      height: $avatar-size;
      justify-content: center;
      width: $avatar-size;
    }

    &-text {
      overflow: hidden;

      > :nth-child(1) {
        @include primer(bold, 0);
      }

      > :nth-child(2) {
        @include brevier(normal, 0);

        color: var(--color-grey--dark);
      }

      > .is-user-role {
        text-transform: uppercase;
      }

      > :nth-child(1),
      > :nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-actiongroup {
      box-sizing: content-box;
      flex-shrink: 0;
      margin-left: auto;
    }

    &-actions {
      background-color: transparent;
      border-radius: 50%;
      box-sizing: content-box;
      color: var(--color-grey--dark);
      cursor: pointer;
      flex-shrink: 0;
      margin-left: auto;
      overflow: hidden;
      padding: calc(0.5 * var(--unit));
      transition: all var(--transition-time);

      &:hover {
        background-color: var(--color-grey--ultralight);
        color: var(--text-color);
      }
    }

    &-icon {
      color: var(--color-grey--dark);

      &.is-online {
        color: var(--online-color);
      }

      &.is-offline {
        color: var(--offline-color);
      }

      &.has-bad-connection {
        color: var(--warning-color);
      }
    }
  }

  &__body {
    border-top: 1px solid var(--border-color);
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    &-text-message {
      margin: var(--unit);

      @media screen and (min-width: 768px) {
        margin: calc(2 * var(--unit));
      }

      @include primer(bold, 0);
    }
  }

  &__action-button {
    padding: var(--unit);
  }

  &__dialog-action-buttons {
    border-top: 1px solid var(--border-color);
    display: flex;
    margin-top: var(--unit);

    button {
      background-color: transparent;
      border-radius: unset;
      font-size: var(--unit);
      height: 3rem;
      transition: background-color var(--transition-time);
      width: 100%;

      &:hover {
        background-color: var(--color-grey--ultralight);
      }

      &:not(:first-child) {
        border-left: 1px solid var(--border-color);
      }

      &.is-constructive {
        color: var(--color-primary);
      }

      &.is-destructive {
        color: var(--color-grey--dark);
      }
    }
  }

  &__stats {
    padding: var(--unit);

    &-list {
      display: grid;
      gap: var(--unit);
      grid-template-columns: repeat(3, auto);

      @media screen and (min-width: 768px) {
        gap: calc(2 * var(--unit));
      }
    }

    &-title {
      @include brevier(bold);

      color: var(--color-grey--dark);
    }

    &-item {
      overflow: hidden;
      text-overflow: ellipsis;

      > .is-label {
        @include minion(normal, 0);

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        display: block;

        @media screen and (min-width: 768px) {
          @include brevier(normal, 0);
        }
      }

      > .is-number {
        @include paragon(bold, 0);

        display: block;

        &.is-trending-up,
        &.is-trending-down {
          align-items: center;
          display: flex;

          &:after {
            color: var(--error-color);
            content: '\25BE';
            display: block;
            font-size: 1rem;
            margin-inline-start: 4px;
          }
        }

        &.is-trending-up {
          &:after {
            color: var(--success-color);
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  &__action {
    align-items: center;
    border-top: 1px solid var(--border-color);
    display: flex;
    height: var(--card-actions-height);
    justify-content: flex-end;
    margin-top: auto;
    padding-inline-end: var(--unit);

    &.has-no-border {
      border: 0;
    }
  }

  &__banner {
    align-items: center;
    background-color: var(--color-grey--ultralight);
    color: var(--text-color);
    display: flex;
    padding: var(--unit);

    &.has-error {
      background-color: var(--error-color--light);
    }

    &.has-warning {
      background-color: var(--warning-color--light);
    }

    &.is-link {
      cursor: pointer;

      &:hover {
        &.has-warning {
          background-color: var(--error-color--light);
        }
      }
    }

    > .is-icon {
      color: inherit;
      margin-right: calc(2 * var(--unit));

      @at-root {
        .o-card__banner {
          &.has-error > .is-icon {
            color: var(--error-color);
          }

          &.has-warning > .is-icon {
            color: var(--warning-color);
          }
        }
      }
    }

    > .secondary-icon {
      align-items: center;
      color: var(--icon-color);
      display: flex;
      fill: var(--icon-color);
      line-height: 1;
      margin-left: auto;

      $icon-size: 40px;
    }
  }

  &__banner-message {
    > .is-primary-text {
      @include primer(regular, 0);
      display: block;
    }

    > .is-secondary-text {
      @include brevier(regular, 0);

      color: var(--color-grey--dark);
      display: block;
    }
  }

  .is-error-text {
    color: var(--error-color);
  }

  .is-decommissioned {
    color: var(--color-grey--dark);
  }
}

.o-card-list {
  display: grid;
  height: 100%;
  grid-template-areas:
    'title'
    'filter-chip'
    'filter-search'
    'lists';
  grid-template-rows: min-content min-content min-content auto;

  &__title {
    display: flex;
    flex-direction: column;
    gap: var(--unit);
    grid-area: title;
    padding: var(--unit);
    width: max-content;

    @media screen and (min-width: 500px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      width: initial;
    }
  }

  /* chip list
     */
  &__filter-chip {
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    padding: var(--unit);
    grid-area: filter-chip;

    @media screen and (min-width: 768px) {
      display: flex;
      padding-block-end: calc(0.5 * var(--unit));
    }

    &-text {
      @include brevier();
      white-space: nowrap;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-right: calc(2 * var(--unit));
      }
    }
  }

  &__filter-search {
    display: flex;
    justify-content: space-between;
    padding: var(--unit);
    grid-area: filter-search;

    &-checks {
      align-items: center;
      display: flex;

      > :nth-child(1) {
        padding-right: var(--unit);
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      &-checks {
        padding-top: var(--unit);
      }
    }
  }

  &__lists {
    margin-top: var(--unit);
    padding: 0 var(--unit);
    overflow: auto;
    grid-area: lists;

    @media screen and (min-width: 768px) {
      margin-top: calc(2 * var(--unit));
    }
  }
}
