$button-height: 38px;

:root .o-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: var(--border-radius);
  color: var(--link-color);
  cursor: pointer;
  display: flex;
  font-family: var(--regular-font);
  font-size: var(--unit);
  font-weight: var(--regular-font-weight);
  height: $button-height;
  line-height: 1;
  padding-left: calc(0.5 * var(--unit));
  padding-right: calc(0.5 * var(--unit));
  text-transform: uppercase;
  transition: all var(--transition-time);
  white-space: nowrap;
  width: fit-content;
  position: relative;

  &:hover {
    color: var(--color-primary--darker);
  }

  &.is-disabled {
    color: var(--disabled-color);
  }

  > .is-icon-right {
    margin-inline-start: calc(0.5 * var(--unit));
    transition: margin var(--transition-time);

    @at-root {
      .o-button:hover > .is-icon-right {
        margin-inline-end: calc(0.5 * var(--unit) - var(--unit));
        margin-inline-start: var(--unit);

        &.has-no-animation {
          margin-inline-end: initial;
          margin-inline-start: calc(0.5 * var(--unit));
        }
      }
    }
  }

  > .is-icon-left {
    margin-inline-end: calc(0.5 * var(--unit));
  }

  &.is-ghost {
    background-color: transparent;
    border-radius: var(--border-radius);
    color: var(--color-white);

    &:hover {
      opacity: 85%;
    }
  }

  &.has-outline {
    border-radius: var(--border-radius);
    border-style: solid;
    border-width: 1px;
  }

  &.is-contained {
    background-color: var(--color-grey--ultralight);
    border-radius: var(--border-radius);

    &:hover {
      background-color: transparent;
    }
  }

  &.is-primary {
    background-color: var(--color-primary);
    color: var(--color-white);

    &:hover {
      background-color: var(--color-primary--darker);
    }
  }

  &.is-muted {
    color: var(--color-grey--dark);

    &:hover {
      color: var(--color-primary--darker);
    }
  }

  &.is-small {
    @include brevier(normal, 0);
  }

  &.is-right {
    float: right;
  }

  &.is-delete {
    color: var(--error-color);

    .is-icon-left,
    .is-icon-right {
      color: var(--error-color);
      transition: color var(--transition-time);
    }

    &:hover {
      color: var(--text-color);
      > .is-icon-left,
      > .is-icon-right {
        color: var(--text-color);
      }
    }
  }

  &.is-error {
    color: var(--color-white);
    background-color: var(--error-color);
  }
}

.o-button--icon {
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  color: var(--link-color);
  cursor: pointer;
  display: flex;
  height: $button-height;
  justify-content: center;
  margin-bottom: calc(0.5 * var(--unit));
  transition: all var(--transition-time);
  width: $button-height;

  &.has-outline {
    border: 1px solid var(--link-color);
  }

  &.is-grey {
    color: var(--color-grey--dark);
  }

  &.is-contained {
    background-color: var(--color-grey--ultralight);

    &:hover {
      background-color: var(--color-grey--light);
    }
  }

  &.has-white-bg {
    background-color: var(--color-white);

    &:hover {
      background-color: var(--color-grey--ultralight);
    }
  }

  &.has-primary-bg {
    background-color: var(--link-color);
    color: var(--color-white);

    > * {
      color: var(--color-white);
    }

    &:hover {
      background-color: var(--color-primary--darker);
    }
  }

  &:hover {
    background-color: var(--color-grey--ultralight);
    color: var(--color-primary--darker);
  }
}

.o-button-flush-group {
  align-items: center;
  border-radius: var(--border-radius);
  display: flex;
  overflow: hidden;
  > .o-button {
    border-radius: 0 !important;
  }
}

.o-button__date-label {
  align-items: center;
  display: flex;
  gap: calc(0.25 * var(--unit));
}
