/*  .o-view
 *  A simple one col/full screen container to wrap page content with some padding
 *
 *  Add .has-left-menu
 *  to create a simple 2 col layout with fixed width left col, flush to screen edge
 *  uses are limited - but used in some site listing views
 *  Breadcrumb nav should sit outside of .o-view
 */

.o-view-2 {
  display: grid;
  padding: calc(0.5 * var(--unit));
  grid-template-rows: auto 1fr;
}

.o-view {
  display: grid;
  padding: calc(0.5 * var(--unit));

  @media screen and (min-width: 768px) {
    padding: var(--unit);
  }

  @media screen and (min-width: 1024px) {
    padding: calc(2 * var(--unit));
  }

  &.is-full-height {
    height: 100%;
    overflow: auto;
  }

  &.has-left-menu {
    @media screen and (min-width: 768px) {
      gap: calc(2 * var(--unit));
      grid-template-columns: 360px 1fr;
      padding: 0;
      padding-right: var(--unit);
    }

    @media screen and (min-width: 1024px) {
      grid-template-columns: 400px 1fr;
      padding-right: calc(2 * var(--unit));
    }

    @media screen and (min-width: 1500px) {
      grid-template-columns: 500px 1fr;
      grid-template-rows: 1fr;
    }
  }

  &__main {
    padding-bottom: var(--unit);

    @media screen and (min-width: 768px) {
      padding-bottom: calc(2 * var(--unit));
    }
  }
}

/*  .o-view--2col
 *  a more widely usable layout style than .o-view.has-left-menu
 *
 *  This layout should wrap breadcrumb nav, Page title/intro text and
 *  left/aside and main content areas
 *
 *  <div class="o-view--2col">
 *     <div class="is-breadcrumb"> ... </div>
 *     <div class="is-title"> ... </div>
 *     <div class="is-aside"> ... </div>
 *     <div class="is-main"> ... </div>
 *   </div>
 */
.o-view--2col {
  display: grid;
  grid-template-areas:
    'breadcrumb'
    'title'
    'aside'
    'main';
  grid-template-rows: min-content min-content min-content auto;
  min-height: 100%;
  padding-inline: calc(0.5 * var(--unit));
  height: 100%;

  @media screen and (min-width: 1365px) {
    grid-template-areas:
      'breadcrumb breadcrumb breadcrumb breadcrumb'
      '. aside main .';
    grid-template-columns: var(--unit) 360px auto var(--unit);
    grid-template-rows: min-content 1fr;
    padding: 0;
  }

  > .is-breadcrumb {
    grid-area: breadcrumb;
    margin-bottom: var(--unit);

    &:has(.o-modal-full-page) {
      padding: 0 !important;
    }
  }

  > .is-title {
    grid-area: title;
  }

  > .is-aside {
    grid-area: aside;
    margin-bottom: var(--unit);
    overflow: auto;
  }

  > .is-main {
    display: flex;
    flex-direction: column;
    grid-area: main;
    margin-bottom: var(--unit);
    overflow: auto;
    height: 100%;

    @media screen and (min-width: 1365px) {
      margin-left: calc(2 * var(--unit));
    }
  }
}
