.o-primary-radio-group {
  .mdc-form-field {
    padding: var(--unit);
    margin-bottom: var(--unit);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-color);
    width: 100%;
    > label {
      > .is-primary-text {
        @include primer(bold, 0);
      }

      > .is-secondary-text {
        @include brevier(normal, 0);
      }
    }
  }

  .mat-mdc-radio-checked {
    .mdc-radio__inner-circle,
    .mdc-radio__outer-circle {
      border-color: var(--color-white) !important;
    }
    .mdc-form-field {
      background-color: var(--primary-color);

      > label {
        color: var(--color-white);
      }
    }
  }
}
