.o-chart {
  &__video-missing-bg {
    background-color: var(--error-color);
  }

  &__video-delay-bg {
    background-color: var(--color-mikado--light-1);
  }

  &__video-success-bg {
    background-color: var(--color-apple--light-2);
  }
}
