.o-category-card {
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__head {
    align-items: center;
    background-color: var(--color-primary);
    border-bottom: none;
    color: var(--color-white);
    display: flex;
    gap: var(--unit);
    padding: var(--unit);
    padding-right: calc(0.5 * var(--unit));

    > .is-text {
      color: var(--color-white);
      font-weight: bold;
    }
  }

  &__body {
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
    padding-block: var(--unit);
    text-overflow: ellipsis;

    > .is-media {
      align-items: center;
      display: flex;
      flex: 1;
      justify-content: center;

      > img {
        max-height: 180px;
      }

      .is-grayscale {
        filter: grayscale(100%);
      }
    }
  }

  &__action {
    align-items: center;
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-shrink: 0;
    height: var(--card-actions-height);
    justify-content: flex-end;
    margin-top: auto;
    padding-inline-end: var(--unit);
  }
}
