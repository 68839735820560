.o-content-panel {
  background-color: var(--color-white);
  border-radius: var(--border-radius);

  &.is-full-height {
    height: 100%;
  }

  &__head {
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    gap: var(--unit);
    flex-wrap: wrap;
    justify-content: space-between;
    padding: var(--unit);

    &.has-no-border {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__body {
    padding: var(--unit);
  }
}
