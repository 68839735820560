/* ------------------------------------------------------------------------ *\
    All headings have two lines. Heading and subhead.
    If you want a heading with only one line, use a regular text style.
    Style name is based on the largest font size. eg:
    .o-heading--primer, will use primer font size for the main heading
    and the subhead font will be automatically set.
\* ----------------------------------------------------------------------- */

.o-heading--primer {
  margin-bottom: var(--unit);

  > :nth-child(1) {
    @include primer(bold, 0);
  }

  > :nth-child(2) {
    @include brevier(normal, 0);

    color: var(--color-grey--dark);
  }
}

.o-heading--normal {
  margin-bottom: var(--unit);

  > :nth-child(1) {
    @include primer(normal, 0);
  }

  > :nth-child(2) {
    @include brevier(normal, 0);

    color: var(--color-grey--dark);
  }
}

.o-heading--long-primer {
  margin-bottom: var(--unit);

  > :nth-child(1) {
    @include long-primer(bold, 0);
  }

  > :nth-child(2) {
    @include brevier(normal, 0);

    color: var(--color-grey--dark);
  }
}

.o-heading--pica {
  margin-bottom: var(--unit);

  > :nth-child(1) {
    @include pica(bold, 0);
  }

  > :nth-child(2) {
    @include brevier(normal, 0);

    color: var(--color-grey--dark);
  }
}

.o-heading--primer,
.o-heading--normal,
.o-heading--long-primer,
.o-heading--pica {
  overflow: hidden;

  > :nth-child(1) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > :nth-child(2) {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.is-all-black {
    > :nth-child(2) {
      color: var(--text-color);
    }
  }

  &.is-wrapping {
    > :nth-child(2) {
      white-space: normal;
    }
  }

  /*  In the cold light of real world usage, the nowrap standard causes layout issues on mobile views.
      It should probably be removed across the board. Until that work is done - this fix should probably
      be applied as standard
  */
  &.is-all-wrapping {
    > :nth-child(1),
    > :nth-child(2) {
      white-space: normal;
    }
  }
}
